import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Firebase
import { db } from '../../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleSideNav, setPage, toggleCommentModal } from '../../actions/navActions';
import { setPosts, getPostById } from '../../actions/postActions';

// Layout Wrapper
import Layout from '../../components/layout/Layout';

// Components
import { Button } from '@material-ui/core';
import Spinner from '../../components/common/Spinner';
import Image_Block from './Image_Block';
import Detail from '../../components/PostPage/Detail';

// Modal

const Detail_MediaPage = ({ 
    nav: { 
        sideNav, 
        campus_id,
        campus_name,
        campus_loading
    },
    post: { 
        post, 
        loading 
    }, 
    setPage, 
    toggleCommentModal,
    getPostById,
    toggleSideNav,
    setPosts,
    match,
    history
}) => {

    // State variable to track if Mixpanel event has been sent
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Posts
    const [posts, setFeedPosts] = useState([]);
    const [gotPosts, setGotPosts] = useState(false);

    // Modal toggles
    const [usernameModal, setUsernameModal] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const [discardModal, setDiscardModal] = useState(false);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Inifite scroll
    const [lastPageDoc, setLastPageDoc] = useState(null);
    const [noMorePosts, setNoMorePosts] = useState(false);
    const [showBottomSpinner, setShowBottomSpinner] = useState(false)

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Firebase collection ref
    const postsCollectionRef = collection(db, "posts");
    let q;
    // Construct a new query starting at the last visible document and fetch the next 3 posts.
    let nextQuery;
    
    if(campus_id.length > 0) {
        if(campus_name === 'OU') {
            q = query(postsCollectionRef, where("campus_id", "==", "b7nqQGbbbzw08uh0MoPA"), where("post_commented_on", "==", null), where("img_count", ">=", 1), orderBy('img_count'), orderBy('lastModified', 'desc'), limit(15));
            nextQuery = query(postsCollectionRef, where("campus_id", "==", "b7nqQGbbbzw08uh0MoPA"), where("post_commented_on", "==", null), where("img_count", ">=", 1), orderBy('img_count'), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(15));
        } else if(campus_name === 'UT') {
            q = query(postsCollectionRef, where("campus_id", "==", "j4R70faL7jaXTR5Nzeex"), where("post_commented_on", "==", null), where("img_count", ">=", 1), orderBy('img_count'), orderBy('lastModified', 'desc'), limit(15));
            nextQuery = query(postsCollectionRef, where("campus_id", "==", "j4R70faL7jaXTR5Nzeex"), where("post_commented_on", "==", null), where("img_count", ">=", 1), orderBy('img_count'), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(15));
        }
        else{
            q = query(postsCollectionRef, where("post_commented_on", "==", null), where("img_count", ">=", 1), orderBy('img_count'), orderBy('lastModified', 'desc'), limit(15));
            nextQuery = query(postsCollectionRef, where("post_commented_on", "==", null), where("img_count", ">=", 1), orderBy('img_count'), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(15));
        }
    } else {
        q = query(postsCollectionRef, where("post_commented_on", "==", null), where("img_count", ">=", 1), orderBy('img_count'), orderBy('lastModified', 'desc'), limit(15));
        nextQuery = query(postsCollectionRef, where("post_commented_on", "==", null), where("img_count", ">=", 1), orderBy('img_count'), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(15));
    }

    if(!gotPosts && !campus_loading) {
        // Fetch initial posts
        onSnapshot(q, async (snapshot) => {
            console.log('UPDATING POSTS...');
            const tempPostList = snapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

            setFeedPosts(tempPostList);
            setPosts(tempPostList);
            
            setGotPosts(true);

            // Get the last visible document for infinite scroll
            setLastPageDoc(snapshot.docs[snapshot.docs.length - 1]);
        })
    }

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;

    // Start - Infinite Scroll

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {

                // scrolling up - show top nav
                setScrollDirection("up");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

        // Check if the user has scrolled to the bottom of the content and conditions for loading more posts are met
        if (newScrollY >= scrollHeight - 1 && !showBottomSpinner && !noMorePosts && gotPosts) {
            console.log('scrolling');
            loadMore();
        }
    }

    // Function to load more paginated posts
    const loadMore = () => {
        console.log("last", lastPageDoc);

        // Show spinner
        setShowBottomSpinner(true);

        // Fetch the next set of posts
        onSnapshot(nextQuery, async (snapshot) => {
            // If there are no more documents in the database to load
            if(snapshot.docs.length < 2) {
                setNoMorePosts(true);
            } else {

                console.log('UPDATING POSTS...');
                
                const tempPostList = [...posts, ...snapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}))];

                // Update the posts state with the new posts
                setFeedPosts(tempPostList);
    
                // Get the last visible document for the next load
                setLastPageDoc(snapshot.docs[snapshot.docs.length - 1]);
    
                // Hide the loading spinner
                setShowBottomSpinner(false);
            }

        })
    }

    // END - Infinite Scroll

    let postList;

    // Check if posts are null or not yet fetched
    if(posts === null || !gotPosts) {
        postList = <Spinner />;
    }
    else {
        if(posts.length > 0) {

            // Iterate through the posts array and render each post component
            postList = posts.map(post => {
                return (

                    <Image_Block key={post._id} detailPost={post} />
                )
            })
        }
        else {
            // Display a message when there are no posts
            postList = (
                <div className="no-rides">
                    <h1>No Photos / Videos</h1>
                </div>
            );
        }
    }

    // ---- Detail Post Info -----

    // Toggle the `displayModal` 
    const handleDisplayModal = () => {
        setDisplayModal(!displayModal)
    }

    // Toggle the `discardModal` 
    const handleCommentModal = () => {
        toggleDiscardModal();
    }

    // Toggle the `discardModal` 
    const toggleDiscardModal = () => {
        setDiscardModal(!discardModal);
    }

    const handleDiscard = () => {

        // Close the discard modal
        setDiscardModal(false);

        // Handle the discard
        toggleCommentModal();
    }

    let postContent;

    if(loading) {
        postContent = <Spinner />;
    }
    else {
        if(post === null) {
            postContent = (
                <div className="no-post">
                    <p>Hmm...this page doesn’t exist. Try searching for something else.</p>
                    <Button  
                        onClick={() => window.location ='/search'}
                        className="noChats__btn"
                    >
                        Search
                    </Button>
                </div>
            );
        } else {
            postContent =  (
                <Fragment>
                    <Detail 
                        post={post}
                        toggleCommentModal={toggleCommentModal}
                        history={history}
                        isTablet={isMobile}
                        // commentList={commentList}
                        setScrollDirection={setScrollDirection}
                        setOldScrollY={setOldScrollY}
                        oldScrollY={oldScrollY}
                    />

                </Fragment>
            );
        }
    }

    // ---- END: Detail Post Info ----

    useEffect(() => {

        // Set the current page
        setPage('Detail Media Page');

        // Fetch the post data based on the `match.params.id` parameter
        getPostById(match.params.id);

    }, [ match.params.id ]);

    // Handles Mixpanel tracking
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

        // Track "About Page View" event
        mixpanel.track("About Page View");
    }

    // Checks if the environment is production and Mixpanel tracking hasn't been sent
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {

        // Call Mixpanel tracking function
        handleMixpanel();

        // Set Mixpanel event as sent
        setSentMixpanel(true);
    }

    return (
        <Fragment>
            <Layout handleScroll={handleScroll} sideNav={sideNav} handleSlideMenu={toggleSideNav} page="media post">

                {postContent}

                <div style={{textAlign: 'center', color: 'rgb(29, 155, 240)', fontSize: '16px', fontWeight: 'bold', padding: '20px 10px', borderBottom: '5px solid #e6ecf0'}}>
                    <span>View Comments</span>
                </div>

                <div style={{background: '#f5f8fa', textAlign: 'center', color: 'grey', fontSize: '16px', fontWeight: 'bold', padding: '10px', borderBottom: '1px solid #e6ecf0'}}>
                    <span>More posts...</span>
                </div>

                <div style={{width: '100%', height: 'fit-content', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridAutoRows: 'min-content', borderBottom: '1px solid #ddd'}}>
                    {/* Render the postList */}
                    {postList}
                </div>

                {/* Add space to the bottom of the page b/c of auth alert  */}
                <div style={{height: '200px'}}></div>
            </Layout>

        </Fragment>
    )
}

Detail_MediaPage.propTypes = {
    nav: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
    setPage: PropTypes.func.isRequired, // Function prop to set the current page in the navigation
    toggleCommentModal: PropTypes.func.isRequired,
    getPostById: PropTypes.func.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    setPosts: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    nav: state.nav,
    post: state.post
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    setPage, 
    toggleCommentModal,
    getPostById,
    toggleSideNav,
    setPosts
})(Detail_MediaPage);

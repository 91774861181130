import React from 'react'
import PropTypes from 'prop-types'

const Readme = props => {
    return (
        <div className='readme_body'>
            <main>
        <h1 id="pong-game-with-pygame">Space Invaders Game</h1>
<p>A classic <strong>Space Invaders</strong> game built using <strong>Python</strong> and <strong>Pygame</strong>. Players control a spaceship to defend Earth from waves of alien invaders, utilizing lasers to shoot down enemies while avoiding enemy lasers and obstacles. </p>
<p>The program also includes optional web hosting support using <strong>asyncio</strong> and <strong>pygbag</strong>.</p>
<p><img src="https://github.com/tunde262/space_invaders_game_python/blob/main/assets/thumbnail.png?raw=true" alt="thumbnail" /></p>
<h2 id="features">Features</h2>
<ul>
<li><strong>Player-controlled spaceship:</strong> Navigate using the keyboard to dodge alien lasers.</li>
<li><strong>Laser mechanics:</strong> Fire lasers to destroy aliens and their obstacles.</li>
<li><strong>Dynamic enemies:</strong> Aliens advance towards the player, shooting lasers at random intervals.</li>
<li><strong>Obstacles:</strong> Protective barriers shield your spaceship but can be destroyed by both player and alien lasers.</li>
<li><strong>Mystery ship:</strong> A bonus ship appears randomly, offering extra points when destroyed.</li>
<li><strong>High score tracking:</strong> Your highest score is saved locally.</li>
<li><strong>Background music and sound effects:</strong> Engaging audio to enhance the gameplay experience.</li>
<li><strong>Optional Web Hosting</strong> on the web using asyncio compatibility with the Pygbag library..</li>
</ul>
<hr />
<h2 id="requirements">Requirements</h2>
<ul>
<li>Python 3.8+, recommended for the following reasons:<ol>
<li><strong>Enhanced <code>asyncio</code> Support</strong>: The project uses <code>asyncio.run()</code> for the optional web-hosting feature, which simplifies asynchronous execution.</li>
<li><strong>Compatibility with Pygbag</strong>: Pygbag, used for web hosting, requires Python 3.8+.</li></ol></li>
<li>Pygame (install via <code>pip install pygame</code>)</li>
<li>(Optional) Pygbag for web hosting</li>
</ul>
<h2 id="installation">Installation</h2>
<ol>
<li><strong>Clone the Repository</strong>:</li>
</ol>
<pre><code class="hljs bash language-bash">   git <span class="hljs-built_in">clone</span> https://github.com/tunde262/space_invaders_game_python.git
   <span class="hljs-built_in">cd</span> space_invaders_game_python</code></pre>
<ol start="3">
<li><strong>Install Dependencies</strong>: Ensure you have Python 3.8+ installed. Then, install the required libraries:</li>
</ol>
<pre><code class="hljs bash language-bash">   pip install pygame</code></pre>
<ol start="4">
<li><strong>Run the Game</strong>:</li>
</ol>
<pre><code class="hljs bash language-bash">   python main.py</code></pre>
<hr />
<h2 id="web-hosting-optional">Web Hosting (Optional)</h2>
<p>This project supports optional web hosting using <code>asyncio</code> and the <strong>Pygbag</strong> library.</p>
<ol>
<li><strong>Install the Pygbag library</strong>:</li>
</ol>
<pre><code class="hljs bash language-bash">   pip install pygbag</code></pre>
<ol start="2">
<li><strong>Compile and host the game</strong>:</li>
</ol>
<pre><code class="hljs bash language-bash">   pygbag main.py</code></pre>
<ol start="3">
<li>Follow the output instructions to serve the game in a browser.</li>
</ol>
<h2 id="how-to-play">How to Play</h2>
<ul>
<li>Start the game by running the <code>main.py</code> file</li>
<li>Move the spaceship left or right to avoid alien lasers and keep your lives.</li>
<li>Shoot lasers to destroy the alien invaders.</li>
<li>Each alien type gives you different points when destroyed. The harder aliens provide more points.</li>
<li>Objective:<ul>
<li>Destroy all aliens before they reach your position.</li>
<li>Protect your spaceship from alien lasers and destroy the mystery ship for bonus points.</li></ul></li>
<li>Game Over:<ul>
<li>The game ends if all your lives are lost or an alien reaches your spaceship.</li></ul></li>
</ul>
<h2 id="game-controls">Game Controls</h2>
<ul>
<li><code>→</code> - Move spaceship right</li>
<li><code>←</code> - Move spaceship left</li>
<li><code>SPACEBAR</code>: Shoot lasers</li>
</ul>
<hr />
<h2 id="future-enhancements">Future Enhancements</h2>
<ul>
<li>Add a two-player mode for cooperative or competitive gameplay.</li>
<li>Introduce collectible power-ups, such as faster firing, shields, or multi-shot lasers.</li>
<li>Allow players to choose spaceship designs, colors, and difficulty levels.</li>
<li>Mobile-friendly version with touch controls.</li>
<li>Add on-screen instructions.</li>
<li>Enable players to save their progress and resume gameplay later.</li>
</ul>
<hr />
<h3 id="author">Author</h3>
<p><em>Tunde Adepitan</em>  <br />
GitHub: <a href="https://github.com/tunde262">tunde262</a>  <br />
Feel free to connect and give me feedback!</p>
            </main>
        </div>
  )
}

Readme.propTypes = {}

export default Readme;
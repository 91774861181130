import axios from 'axios';
import { setAlert } from './alertActions';
import { toDate } from 'date-fns';

import { db, auth, storage } from '../config/firebase-config';
import { collection, query, where, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithEmailLink, updateEmail, updatePassword, updateProfile, onAuthStateChanged, sendPasswordResetEmail, confirmPasswordReset, signOut } from 'firebase/auth';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';

import mixpanel from 'mixpanel-browser';

import { 
    SET_SIDEBAR, 
    REGISTER_SUCCESS, 
    REGISTER_FAIL, 
    USER_LOADED,
    PROFILE_UPDATE_LOADING, 
    PROFILE_UPDATE_LOADED,
    ACCOUNT_LOADING,
    ACCOUNT_LOADING_DONE,
    GET_USER,
    GET_USERS,
    SET_USERS,
    UPDATE_AUTH, 
    UPDATE_USER_IMG,
    AUTH_ERROR, 
    USER_ERROR, 
    LOGIN_SUCCESS, 
    LOGIN_FAIL, 
    LOGOUT,
    CLEAR_USER,
} from './types';

import { toggleAuthModal } from './navActions';

// Reference to the "users" collection in Firestore
const usersCollectionRef = collection(db, "users");

const postsCollectionRef = collection(db, "posts");
const notificationCollectionRef = collection(db, "notifications");
const chatsCollectionRef = collection(db, "chats");
const messagesCollectionRef = collection(db, "messages");

// Function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const parseDisplayName = (displayName) => dispatch => {
    // Identify common delimiters
    const delimiters = [" ", ".", ",", "-"];

    // Replace all delimiters with spaces for easier splitting
    let normalizedDisplayName = displayName;
    delimiters.forEach(delimiter => {
        normalizedDisplayName = normalizedDisplayName.split(delimiter).join(" ");
    });

    // Split the name into parts by spaces and filter out any parts containing numbers
    const nameParts = normalizedDisplayName.split(" ").filter(part => part.trim() !== "" && !/\d/.test(part));

    // Assign name parts
    let first_name = nameParts[0] || null;
    let last_name = null;
    let middle_name = null;

    if (nameParts.length === 2) {
        last_name = nameParts[1];
    } else if (nameParts.length > 2) {
        last_name = nameParts[nameParts.length - 1];
        middle_name = nameParts.slice(1, -1).join(" ");
    }

    if(!(first_name?.length > 0 || first_name !== null)) {
        first_name = 'anonymous';
    }

    if(!(last_name?.length > 0 || last_name !== null)) {
        last_name = 'user';
    }

    return { first_name, middle_name, last_name };
}

const handle_create_user = async (
    campus_id,
    userCredential, 
    {
        first_name,
        last_name,
        username,
        email,
        phoneNumber,
        photoURL,
        verified
    },
) => {
    console.log('USER');

    // Store the user ID in local storage
    localStorage.setItem("user_id", userCredential.user.uid);

    console.log('Creating User');

    let profile_img_url = null;
    
    // if(photoURL) {
    //     profile_img_url = photoURL;
    // } else if (userCredential?.user?.photoURL) {
    //     profile_img_url = userCredential.user.photoURL;
    // }

    if(verified === null || verified === undefined) {
        verified = false;
    }

    // Create a new user document in the users collection
    setDoc(doc(db, "users", userCredential.user.uid), {
        campus_id,
        first_name: capitalizeFirstLetter(first_name.toLowerCase()),
        last_name: capitalizeFirstLetter(last_name.charAt(0).toLowerCase()) + '.',
        username: username.toLowerCase(),
        email: email.toLowerCase(),
        school_email: '',
        grad_year: '',
        phone: `${phoneNumber ? phoneNumber : ''}`,
        img: profile_img_url,
        birth_date: {
            month: '',
            day: '',
            year: '',
        },
        profile_set_up: false,
        getting_started: false,
        verified,
        school_verified: false,
        type: "individual",
        email_notifications: true, 
        _id: userCredential.user.uid,
        date: Date.now(),
        createdAt: serverTimestamp(),
        lastModified: serverTimestamp()
    })
        .then((result) => {
            console.log('Created User');

            // Remove email link token so if user logs out the email will send again automatically on page load
            window.localStorage.removeItem("emailForSignIn");

            // Load the user data into the Redux store (register = true, login = false)
            // dispatch(loadUser(true, false));

            // ---- SEND TO MIXPANEL ----

            // Check if in production environment
            if (process.env.NODE_ENV === 'production') {

                // Initialize Mixpanel with provided ID and enable debug mode
                mixpanel.init(process.env.REACT_APP_MIXPANEL_ID, {
                    debug: true
                });
    
                // Assign an alias to the user
                mixpanel.alias(userCredential.user.uid);
    
                // Track the event "Complete Registration" and provide event properties
                mixpanel.track("Complete Registration", {
                    "Username": username.toLowerCase(),
                    "First Name": capitalizeFirstLetter(first_name.toLowerCase()),
                    "Last Name": capitalizeFirstLetter(last_name.charAt(0).toLowerCase()) + '.',
                    "Email": email.toLowerCase(),
                    "Registration Date": new Date().toISOString(),
                    "Registration Method": "Sign in link"
                });

                // Set people properties in Mixpanel
                mixpanel.people.set({
                    "$first_name": capitalizeFirstLetter(first_name.toLowerCase()),
                    "$last_name": capitalizeFirstLetter(last_name.charAt(0).toLowerCase()) + '.',
                    "$email": email.toLowerCase(),
                    "Registration Date": new Date().toISOString(),
                    "Registration Method": "Sign in link"
                })
            }

            // ---- END: SEND TO MIXPANEL ----

            setAccountLoadingDone();
            
            if(!verified) {
                // Redirect to main email verification page
                window.location.href = "/verify";
            } else {
                if(campus_id === 'sWlhtpjZ3UFPMwFF4vJX') { // Campus Buy Sell Website
                    window.location.href = "/search_schools";
                } else {
                    window.location.href = "/home";
                }
            }

            // Redirect to settings school email page after the user created
            // window.location.href = "/home";
        });

}

// Load User
export const loadUser = (register, login) => async dispatch => {

    // Log a message indicating that the user is being loaded
    console.log('LOADING USER HERE!!')

    try {

        // Check if the user ID is stored in localStorage
        if(localStorage.user_id) {

            // Log the user ID from localStorage
    
            // Get a reference to the user document in the database
            const docRef = doc(db, 'users', localStorage.user_id)
    
            // Retrieve the user document from the database
            const userDoc = await getDoc(docRef);

            // Log the user data retrieved from the database
            console.log('LOAD USER');

             // Log the user ID for Mixpanel tracking

            // Dispatch the USER_LOADED action with the user data
            dispatch({
                type: USER_LOADED,
                payload: userDoc.data()
            });

            // ---- SEND TO MIXPANEL ----

            // Check if in production environment
            if (process.env.NODE_ENV === 'production') {

                // Initialize Mixpanel with provided ID and enable debug mode
                mixpanel.init(process.env.REACT_APP_MIXPANEL_ID, {
                    debug: true
                });
    
                // If it's a registration and not a login
                if(register && !login) {

                    // Assign an alias to the user
                    mixpanel.alias(userDoc.data()._id);
    
                    // Track the event "Complete Registration" and provide event properties
                    mixpanel.track("Complete Registration", {
                        "Username": userDoc.data().username,
                        "First Name": userDoc.data().first_name,
                        "Last Name": userDoc.data().last_name,
                        "Email": userDoc.data().email,
                        "Registration Date": new Date().toISOString(),
                        "Registration Method": "Sign in link"
                    });
    
                    // Set people properties in Mixpanel
                    mixpanel.people.set({
                        "$first_name": userDoc.data().first_name,
                        "$last_name": userDoc.data().last_name,
                        "$email": userDoc.data().email,
                        "Registration Date": new Date().toISOString(),
                        "Registration Method": "Sign in link"
                    })
                } 
    
                // If it's a login and not a registration
                if(!register && login) {

                    // Identify the user
                    mixpanel.identify(userDoc.data()._id);
    
                    // Track the event "Login" and provide event properties
                    mixpanel.track("Login", {
                        "Username": userDoc.data().username,
                        "First Name": userDoc.data().first_name,
                        "Last Name": userDoc.data().last_name,
                        "Email": userDoc.data().email,
                        "Login Method": "Sign in link",
                        "Login Date": new Date().toISOString(),
                    });
    
                    // Update people properties in Mixpanel
                    mixpanel.people.set({
                        "Last Login Date": new Date().toISOString()
                    });
                } 
            }
            
        } else {

            // If localStorage doesn't have 'user_id', dispatch AUTH_ERROR action
            dispatch({
                type: AUTH_ERROR
            })
        }
    } catch {

        // If an error occurs, dispatch AUTH_ERROR action
        dispatch({
            type: AUTH_ERROR
        })
    }
}

// Get all users
export const getAllUsers = () => async dispatch => {
    try {
        console.log('GETTING ALL USERS')

        // Retrieve all documents from the 'users' collection
        const data = await getDocs(usersCollectionRef);

        // Create a user list by mapping each document to an object and including the document ID
        const userList = data.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('SHOW USER LIST')

        // Dispatch the GET_USERS action with the user list as the payload
        dispatch({
            type: GET_USERS,
            payload: userList
        });
    } catch (err) {
        console.log(err)

        // If an error occurs, dispatch the SET_USERS action with an empty array as the payload
        dispatch({
            type: SET_USERS,
            payload: []
        })
    }
}

// Get all users
export const getAll_School_Verified_Users = () => async dispatch => {
    try {
        console.log('GETTING ALL SCHOOL VERIFIED USERS')

        // Create a query to fetch user documents based on the specified username
        const q = query (usersCollectionRef, where("school_verified", "==", true))

        // Retrieve the query snapshot containing the user documents
        const data = await getDocs(q);

        // Create a user list by mapping each document to an object and including the document ID
        const userList = data.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('HOW MANY PEOPLE HAVE VERIFIED THEIR SCHOOL EMAIL?');
        console.log(userList.length);

    } catch (err) {
        console.log('ERROR getting all school verified users')
        console.log(err)
    }
}

// Set Users for displaying modal
// export const setUsers = (users) =>  {
//     return {
//         // Define the action type as SET_USERS
//         type: SET_USERS,
//         // Provide the users array as the payload
//         payload: users
//     }
// };

// Fill the posts array with everything in the given array
export const setUsers = arrayOfUsers => dispatch => {
    console.log('SETTING USERS NOW');

    dispatch({
        type: SET_USERS,
        payload: arrayOfUsers
    });
};

// Get single User by id
export const getUserById = id => async dispatch => {

    // Dispatch the clearUser action to reset the user state
    dispatch(clearUser());
    try {
        const docRef = doc(db, 'users', id)

        // Retrieve the document with the specified id from the 'users' collection
        const userDoc = await getDoc(docRef);

        console.log('GOT USER BY ID');
  
        // Dispatch the GET_USER action with the user document data as the payload
        dispatch({
            type: GET_USER,
            payload: userDoc.data()
        });
    } catch (err) {

        // If an error occurs, dispatch the USER_ERROR action with an error message and status code as the payload
        dispatch({
            type: USER_ERROR,
            payload: { msg: "something went wrong", status: 500 }
        });
        console.log(err);
    }
}

// Get single User by username
export const getUserByUsername = username => async dispatch => {
    
    // Dispatch the clearUser action to reset the user state
    dispatch(clearUser());
    try {
        console.log("GOT USER BY USERNAME")

        // Create a query to fetch user documents based on the specified username
        const q = query (usersCollectionRef, where("username", "==", username.toLowerCase()))

        // Retrieve the query snapshot containing the user documents
        const querySnapshot = await getDocs(q);
        console.log('FETCHED USERNAME DATA');
        // console.log(querySnapshot.docs[0].data());
        // const docRef = doc(db, 'users', id)
    
        // const userDoc = await getDoc(docRef);
        // const res = await axios.get(`/api/users/username/${username}`);
  
        // Dispatch the GET_USER action with the data of the first document in the query snapshot as the payload
        if(querySnapshot?.docs[0]?.data()) {
            
            dispatch({
                type: GET_USER,
                payload: querySnapshot.docs[0].data()
            });
        } else {
            dispatch({
                type: GET_USER,
                payload: null
            });
        }
    } catch (err) {

        // If an error occurs, dispatch the USER_ERROR action with an error message and status code as the payload
        dispatch({
            type: USER_ERROR,
            payload: { msg: "something went wrong", status: 500 }
        });
        console.log(err);
    }
}

// Update auth inputs
export const updateAuth = (formObj) => dispatch => {
    
    // Dispatch the UPDATE_AUTH action with the provided form object as the payload
    dispatch({
        type: UPDATE_AUTH,
        payload: formObj
    });
}

// Register User w/ Email link
export const register = (
    email,
    first_name,
    last_name,
    campus_id,
    campus_ext
) => async dispatch => {

    // TODO: CREATE CLOUD FUNCTION TO DELETE ALL UN-VERIFIED ACCOUNTS AFTER X DAYS

    console.log('START REGISTER HERE');

    // Generate username by combining first name and last name
    let username = first_name + last_name;

    try {

        // --- Check if Username is taken
        console.log("CHECKING USERNAME")

        // Create a query to check if the username already exists in the users collection
        const usernameQuery = query (usersCollectionRef, where("username", "==", username))

        // Retrieve the list of users with the same username
        const usernameArray = await getDocs(usernameQuery);

        console.log('FETCHED LIST OF USERS W/ USERNAME');

        // Increment Username by 1 if taken
        if(usernameArray.docs.length > 0) {
            username = `${username}-${usernameArray.docs.length + 1}`
        }

        signInWithEmailLink(auth, email, window.location.href)
            .then((userCredential) => {
                handle_create_user(
                    campus_id,
                    userCredential, 
                    {
                        first_name,
                        last_name,
                        username,
                        email
                    }
                )
            })
            .catch ((err) => {
                window.location.href = "/register?redirect=true";
                // alert('An unknown error has occurred with this action')
            });
            

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        console.log('ERROR MSG')
        console.log(err.message);
        console.log('ERROR CODE')
        console.log(err.code);
        
        // --- Handle different error codes and dispatch corresponding actions or alerts
        if(err.code == 'auth/email-already-in-use') {
            dispatch(setAlert("Email has already been taken.", 'danger'));
        }

        if(err.code == 'auth/weak-password') {
            dispatch(setAlert("Password too short.", 'danger'));
        }

        if(err.code == 'auth/invalid-email') {
            dispatch(setAlert("Invalid email address.", 'danger'));
        }

        dispatch({
            type: REGISTER_FAIL
        });
    }
}

// Login User w/ email link
export const login = (
    email,
    campus_ext
) => async dispatch => {

    try {

        signInWithEmailLink(auth, email, window.location.href)
            .then((userCredential) => {
                console.log('USER');

                // Store the user ID in local storage
                localStorage.setItem("user_id", userCredential.user.uid);

                // Remove email link token so if user logs out the email will send again automatically on page load
                window.localStorage.removeItem("emailForSignIn");

                // Load the user data into the Redux store (register = false, login = true)
                dispatch(loadUser(false, true));
            })
            .catch ((err) => {
                window.location.href = "/login?redirect=true";
                // alert('An unknown error has occurred with this action')
            });
        
    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        console.log('ERROR MSG')
        console.log(err.message);
        console.log('ERROR CODE')
        console.log(err.code);
        
        // --- Handle different error codes and dispatch corresponding actions or alerts
        if(err.code == 'auth/too-many-requests') {
            dispatch(setAlert("Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.", 'danger', 7800));
        }

        if(err.code == 'auth/wrong-password') {
            dispatch(setAlert("Wrong password!", 'danger'));
        }

        if(err.code == 'auth/user-not-found') {
            dispatch(setAlert("Incorrect. Please try again.", 'danger'));
        }

        if(err.code == 'auth/internal-error') {
            dispatch(setAlert("Internal server error.", 'danger'));
        }

        if(err.code == 'auth/invalid-email') {
            dispatch(setAlert("Invalid email address.", 'danger'));
        }

        dispatch({
            type: LOGIN_FAIL
        });
    }
}

// Register User w/ Password 
export const registerWithEmailAndPassword = (
    email,
    password,
    first_name,
    last_name,
    campus_id,
    campus_ext
) => async dispatch => {

    dispatch(setAccountLoading());

    // TODO: CREATE CLOUD FUNCTION TO DELETE ALL UN-VERIFIED ACCOUNTS AFTER X DAYS

    console.log('START REGISTER HERE');

    // Generate username by combining first name and last name
    let username = first_name + last_name;

    try {
    
        // --- Check if Username is taken
        console.log("CHECKING USERNAME")

        // Create a query to check if the username already exists in the users collection
        const usernameQuery = query (usersCollectionRef, where("username", "==", username))

        // Retrieve the list of users with the same username
        let usernameArray = await getDocs(usernameQuery);

        console.log('FETCHED LIST OF USERS W/ USERNAME');

        // Increment Username by 1 if taken
        let counter = 2;
        let tempCounter;
        while(!usernameArray.docs.length == 0) {
            let tempUsername = username;
            tempUsername = `${tempUsername}-${counter}`;
            tempCounter = counter;
            counter++

            // Check if the updated username exists
            const updatedUsernameQuery = query (usersCollectionRef, where("username", "==", tempUsername))
            usernameArray = await getDocs(updatedUsernameQuery);
        }

        if(tempCounter) {
            username = `${username}-${tempCounter}`;
        }

        console.log('Final username:', username);

        const userCredential = await createUserWithEmailAndPassword(auth, email, password);

        handle_create_user(
            campus_id,
            userCredential, 
            {
                first_name,
                last_name,
                username,
                email
            },
        )
        
    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        console.log('ERROR MSG')
        console.log(err.message);
        console.log('ERROR CODE')
        console.log(err.code);
        
        // --- Handle different error codes and dispatch corresponding actions or alerts
        if(err.code == 'auth/email-already-in-use') {
            
            dispatch(setAlert("Email has already been taken.", 'danger'));

        } else if(err.code == 'auth/weak-password') {

            dispatch(setAlert("Password too short.", 'danger'));

        } else if(err.code == 'auth/invalid-email') {

            dispatch(setAlert("Invalid email address.", 'danger'));
        } else {
            dispatch(setAlert("Something went wrong. Try again.", 'danger'));
        }

        dispatch(setAccountLoadingDone());

        dispatch({
            type: REGISTER_FAIL
        });
    }
}

// Login User
export const loginWithEmailAndPassword = (
    email, 
    password,
    campus_id
) => async dispatch => {

    dispatch(setAccountLoading());

    try {
        
        const userCredential = await signInWithEmailAndPassword(auth, email, password);

        console.log('USER');

        // Store the user ID in local storage
        localStorage.setItem("user_id", userCredential.user.uid);

        // Remove email link token so if user logs out the email will send again automatically on page load
        window.localStorage.removeItem("emailForSignIn");

        if(campus_id === 'sWlhtpjZ3UFPMwFF4vJX') {
            window.location.href = '/search_schools';
        }

        // Load the user data into the Redux store (register = false, login = true)
        dispatch(loadUser(false, true));
        dispatch(setAccountLoadingDone());
        
    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        console.log('ERROR MSG')
        console.log(err.message);
        console.log('ERROR CODE')
        console.log(err.code);
        
        // --- Handle different error codes and dispatch corresponding actions or alerts
        if(err.code == 'auth/too-many-requests') {
            
            dispatch(setAlert("Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.", 'danger', 7800));

        } else  if(err.code == 'auth/wrong-password') {

            dispatch(setAlert("Wrong password!", 'danger'));

        } else if(err.code == 'auth/user-not-found') {

            dispatch(setAlert("Incorrect. Please try again.", 'danger'));

        } else if(err.code == 'auth/internal-error') {
            
            dispatch(setAlert("Internal server error.", 'danger'));

        } else if(err.code == 'auth/invalid-email') {

            dispatch(setAlert("Invalid email address.", 'danger'));

        } else if(err.code == 'auth/invalid-login-credentials') {

            dispatch(setAlert("Invalid login credentials.", 'danger'));

        } else {
            
            dispatch(setAlert("Something went wrong. Try again.", 'danger'));
        }

        dispatch(setAccountLoadingDone());

        dispatch({
            type: LOGIN_FAIL
        });
    }
}

// Handle Continue with Google
export const signInWithGoogle = (
    campus_id,
    userCredential, 
    {
        displayName,
        email,
        phoneNumber,
        photoURL,
        emailVerified
    }
) => async dispatch => {

    dispatch(setAccountLoading());

    try {

        // --- Check if User exists
        console.log("CHECKING EMAIL")

        // Create a query to check if the username already exists in the users collection
        const userEmailQuery = query (usersCollectionRef, where("email", "==", email))

        // Retrieve the list of users with the same email
        let usersArray = await getDocs(userEmailQuery);

        let user_List = usersArray.docs.map((doc) => ( {...doc.data(), _id: doc.id} ))

        console.log('FETCHED LIST OF USERS W/ SAME EMAIL');

        if(user_List.length == 0) {
            // Sign up user

            console.log('PARSING DISPLAY NAME')

            let {
                first_name,
                last_name,
                middle_name
            } = dispatch(parseDisplayName(displayName));

            // -- Generate username by combining first name and last name
            let username = first_name + last_name;

            // Create a query to check if the username already exists in the users collection
            const usernameQuery = query (usersCollectionRef, where("username", "==", username))

            // Retrieve the list of users with the same username
            let usernameArray = await getDocs(usernameQuery);

            console.log('FETCHED LIST OF USERS W/ USERNAME');

            // Increment Username by 1 if taken
            let counter = 2;
            let tempCounter;
            while(!usernameArray.docs.length == 0) {
                let tempUsername = username;
                tempUsername = `${tempUsername}-${counter}`;
                tempCounter = counter;
                counter++

                // Check if the updated username exists
                const updatedUsernameQuery = query (usersCollectionRef, where("username", "==", tempUsername))
                usernameArray = await getDocs(updatedUsernameQuery);
            }

            if(tempCounter) {
                username = `${username}-${tempCounter}`;
            }

            console.log('Final username:', username);

            // -- END: Generate username by combining first name and last name

            // Create a new document in the "users" collection with the user data
            handle_create_user(
                campus_id,
                userCredential, 
                {
                    first_name,
                    last_name,
                    username,
                    email,
                    phoneNumber,
                    photoURL,
                    verified: true
                }
            )

            // console.log('username', username);
            // console.log('first_name', first_name);
            // console.log('last_name', last_name);
            // console.log('middle_name', middle_name);
            // console.log('email', email);
            // console.log('phoneNumber', phoneNumber);
            // console.log('photoURL', photoURL);

        } else {
            // Login user

            // Store the user ID in local storage
            localStorage.setItem("user_id", user_List[0]._id);

            // Remove email link token so if user logs out the email will send again automatically on page load
            window.localStorage.removeItem("emailForSignIn");

            dispatch(loadUser(false, true));

            if(campus_id === 'sWlhtpjZ3UFPMwFF4vJX') {
                window.location.href = '/search_schools';
            }

            // dispatch(toggleAuthModal(''));
        }
        
    } catch (err) {
        console.log('ERROR SIGNING ING WITH GOOGLE')
        console.log(err);

        dispatch(setAlert("Error Signing In with Google.", 'danger'));
        
        dispatch(setAccountLoadingDone());
    }
}

// Forgot Password - send link
export const passwordReset = async (email) => {

    console.log('SENDING EMAIL')
    return await sendPasswordResetEmail(auth, email)
}

// Forgot Password - reset password
export const confirmThePasswordReset = async (oobCode, newPassword) => {
    
    if(!oobCode || !newPassword) return;
    
    return await confirmPasswordReset(auth, oobCode, newPassword)
}


// Logout
export const logout = () => async dispatch => {
    try {

        // Sign out the user with firebase hook
        await signOut(auth);

        // Dispatch the LOGOUT action to clear the user data from the Redux store
        dispatch({type: LOGOUT})
    } catch (err) {
        console.log(err);
    }
}

// Check if User email exits and redirect to sign in / sign up
export const checkIfUserExists = (
    email
) => async dispatch => {

    dispatch(setAccountLoading());

    email = email.toLowerCase();

    // TODO: CREATE CLOUD FUNCTION TO DELETE ALL UN-VERIFIED ACCOUNTS AFTER X DAYS

    console.log('START CHECKING IF USER EMAIL EXISTS');

    try {
    
        // --- Check if User exists
        console.log("CHECKING EMAIL")

        // Create a query to check if the username already exists in the users collection
        const userEmailQuery = query (usersCollectionRef, where("email", "==", email))

        // Retrieve the list of users with the same email
        let usersArray = await getDocs(userEmailQuery);

        let user_List = usersArray.docs.map((doc) => ({...doc.data(), _id: doc.id}))

        console.log('FETCHED LIST OF USERS W/ SAME EMAIL');

        dispatch(setAccountLoadingDone());

        if(user_List.length == 0) {
            //Go to the sign up screen

            window.location.href = `/register?email=${email} `;
        } else {
            //Go to the login screen

            window.location.href = `/login?email=${email} `;
        }

    } catch (err) {
        console.log('ERROR CHECKING IF USER EXITS')
        console.log(err);

        dispatch(setAccountLoadingDone());
    }
}

// Set type sidebar
export const setSidebar = (type) => {
    return {
        // Type for setting the sidebar
        type: SET_SIDEBAR,
        // Value representing the type of the sidebar
        payload: type
    }
}

// Update profile Img
export const changeProfileImg = (imgData, id) => async dispatch => {
    
    dispatch(setProfileUpdateLoading());


    console.log('CHANGING PROFILE IMG');

    console.log('IMG DATA: ');

    // Reference to the user document in the Firestore database
    const userDoc = doc(db, "users", id);

    // Get img file extension for firebase url
    var fileExt = imgData[0].name.split('.').pop();

    console.log('PHOTO FILE EXT');

    // Storage reference for the user's profile picture
    const storageRef = ref(storage, `images/profilePics/${id}.${fileExt}`);

    try {

        // Upload the image file to the specified storage location
        const res = await uploadBytesResumable(storageRef, imgData[0]);

        console.log('Uploaded a PROFILE PIC file!');

        // Get the download URL of the uploaded image
        const photoURL = await getDownloadURL(storageRef);

        console.log('IMG PATH');

        // Update the user's profile image in the Firebase Authentication system
        updateProfile(auth.currentUser, { photoURL })

        console.log('UPDATING USER IMG OBJ')
        
        // Update the user document with the new image URL and profile setup status
        await updateDoc(userDoc, {
            img: photoURL,
            profile_set_up: true
        })

        // --- UPDATE ALL USER POSTS ---

        // Query posts collection to fetch posts by user ID
        const userPostsQuery = query(postsCollectionRef,  where("user._id", "==", id));

        // Execute the query and retrieve the query snapshot
        const userPosts = await getDocs(userPostsQuery);

        // Map the query snapshot to an array of notification objects with document ID
        for (const doc of userPosts.docs) {

            const userPost = await getDoc(doc.ref);

            // --- UPDATE USER COMMENTS ---
            
            const commentsCollectionRef = collection(doc.ref, "comments")

            // Create a query to fetch the comments
            const q = query(commentsCollectionRef);

            // Fetch the comment data
            const commentData = await getDocs(q);
            
            for (const commentDoc of commentData.docs) {

                if(commentDoc.data().user._id === auth.currentUser.uid) {

                    await updateDoc(commentDoc.ref, {
                        ...commentDoc.data(),
                        avatar: photoURL,
                        user: {
                            ...commentDoc.data().user,
                            img: photoURL,
                        },
                    });
                }
            }

            // END UPDATE COMMENTS

            await updateDoc(doc.ref, {
                ...userPost.data(),
                avatar: photoURL,
                user: {
                    ...userPost.data().user,
                    img: photoURL,
                },
            })
        };

        // END UPDATE POSTS

        // --- UPDATE USER NOTIFICATIONS ---

        const userNotifysQuery = query(notificationCollectionRef, where("from_user", "==", id));
        const userNotifications = await getDocs(userNotifysQuery);

        for (const notifyDoc of userNotifications.docs) {

            const userNotification = await getDoc(notifyDoc.ref);

            await updateDoc(notifyDoc.ref, {
                ...userNotification.data(),
                from_avatar: photoURL,
            });

        }

        // END UPDATE NOTIFICATIONS

        // --- UPDATE USER CHATS ---

        const userChatsQuery = query(chatsCollectionRef, where("current_users", "array-contains", auth.currentUser.uid));
        const userChats = await getDocs(userChatsQuery);

        for (const chatDoc of userChats.docs) {

            const userChat = await getDoc(chatDoc.ref);

            // Check if the authenticated user is the recipient of the chat (to_user)
            if(auth.currentUser.uid === userChat.data().to_user._id) {
                
                await updateDoc(chatDoc.ref, {
                    ...chatDoc.data(),
                    to_user: {
                        ...chatDoc.data().to_user,
                        img: photoURL
                    },
                });
            } 
            // Check if the authenticated user is the sender of the chat (from_user)
            else if (auth.currentUser.uid === chatDoc.data().from_user._id) {
                
                await updateDoc(chatDoc.ref, {
                    ...chatDoc.data(),
                    from_user: {
                        ...chatDoc.data().from_user,
                        img: photoURL
                    },
                });
            }

        }

        // END UPDATE CHATS

        // --- UPDATE USER MESSAGES ---

        const userMessagesQuery = query(messagesCollectionRef, where("user", "==", auth.currentUser.uid));
        const userMessages = await getDocs(userMessagesQuery);

        for (const messageDoc of userMessages.docs) {

            const userMessage = await getDoc(messageDoc.ref);

            await updateDoc(messageDoc.ref, {
                ...userMessage.data(),
                avatar: photoURL,
            });

        }

        // END UPDATE MESSAGES

        dispatch({
            type: UPDATE_USER_IMG,
            payload: photoURL
        });

        // Dispatch a success alert to notify the user about the image update
        dispatch(setAlert('Account Updated!', 'success'));
        dispatch(setProfileUpdateLoaded());

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        if(err.response) {
            const errors = err.response.data.errors;

            // Dispatch error alerts for each error message received
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        } else {

            // Dispatch a generic error alert if no specific error information is available
            setAlert('Something went wrong', 'danger')
        }
    }
}

// Update auth inputs
export const editUserName = (formData) => async dispatch => {

    dispatch(setProfileUpdateLoading());

    const { first_name, last_name, userId } = formData;

    const username =  capitalizeFirstLetter(first_name.toLowerCase()) + ' ' + capitalizeFirstLetter(last_name.charAt(0).toLowerCase()) + '.';

    // Reference to the user document in the Firestore database
    const userDoc = doc(db, "users", userId);

    console.log('UPDATING USER NAMES');

    // Try block to handle the update operation
    try {

        console.log('USER NAMES DATA')
        
        // Update the user document with the new first name and last name
        await updateDoc(userDoc, {
            first_name,
            last_name
        })

        // --- UPDATE ALL USER POSTS ---

        const userPostsQuery = query(postsCollectionRef, where("user._id", "==", userId));
        const userPosts = await getDocs(userPostsQuery);

        for (const doc of userPosts.docs) {

            const userPost = await getDoc(doc.ref);

            // --- UPDATE USER COMMENTS ---
            
            const commentsCollectionRef = collection(doc.ref, "comments")

            // Create a query to fetch the comments
            const q = query(commentsCollectionRef);

            // Fetch the comment data
            const commentData = await getDocs(q);
            
            for (const commentDoc of commentData.docs) {

                if(commentDoc.data().user._id === auth.currentUser.uid) {

                    await updateDoc(commentDoc.ref, {
                        ...commentDoc.data(),
                        username,
                        user: {
                            ...commentDoc.data().user,
                            first_name: first_name,
                            last_name: last_name
                        },
                    });
                }
            }

            // END UPDATE COMMENTS

            await updateDoc(doc.ref, {
                ...userPost.data(),
                username,
                user: {
                    ...userPost.data().user,
                    first_name: first_name,
                    last_name: last_name
                },
            });

        }

        // END UPDATE POSTS

        // --- UPDATE USER NOTIFICATIONS ---

        const userNotifysQuery = query(notificationCollectionRef, where("from_user", "==", userId));
        const userNotifications = await getDocs(userNotifysQuery);

        for (const notifyDoc of userNotifications.docs) {

            const userNotification = await getDoc(notifyDoc.ref);

            await updateDoc(notifyDoc.ref, {
                ...userNotification.data(),
                from_username: username,
            });

        }

        // END UPDATE NOTIFICATIONS

        // --- UPDATE USER CHATS ---

        const userChatsQuery = query(chatsCollectionRef, where("current_users", "array-contains", auth.currentUser.uid));
        const userChats = await getDocs(userChatsQuery);

        for (const chatDoc of userChats.docs) {

            const userChat = await getDoc(chatDoc.ref);

            // Check if the authenticated user is the recipient of the chat (to_user)
            if(auth.currentUser.uid === userChat.data().to_user._id) {
                
                await updateDoc(chatDoc.ref, {
                    ...chatDoc.data(),
                    to_user: {
                        ...chatDoc.data().to_user,
                        first_name,
                        last_name
                    },
                });
            } 
            // Check if the authenticated user is the sender of the chat (from_user)
            else if (auth.currentUser.uid === chatDoc.data().from_user._id) {
                
                await updateDoc(chatDoc.ref, {
                    ...chatDoc.data(),
                    from_user: {
                        ...chatDoc.data().from_user,
                        first_name,
                        last_name
                    },
                });
            }

        }

        // END UPDATE CHATS

        // --- UPDATE USER MESSAGES ---

        const userMessagesQuery = query(messagesCollectionRef, where("user", "==", auth.currentUser.uid));
        const userMessages = await getDocs(userMessagesQuery);

        for (const messageDoc of userMessages.docs) {

            const userMessage = await getDoc(messageDoc.ref);

            await updateDoc(messageDoc.ref, {
                ...userMessage.data(),
                user_name: username,
            });

        }

        // END UPDATE MESSAGES

        // Get updated User data for display
        const updatedUserDoc = await getDoc(userDoc);

        console.log('GOT UPDATED USER BY ID');
  
        // Dispatch an action to update the authentication state with the updated user data
        dispatch({
            type: UPDATE_AUTH,
            payload: updatedUserDoc.data()
        });

        // Dispatch a success alert to notify the user about the profile update
        dispatch(setAlert('Profile Updated', 'success'));
        dispatch(setProfileUpdateLoaded());

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        if(err.response) {
            const errors = err.response.data.errors;

            // Dispatch error alerts for each error message received
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        } else {

            // Dispatch a generic error alert if no specific error information is available
            setAlert('Something went wrong', 'danger')
        }
    }
}

// Update auth inputs
export const editUsername = (formData) => async dispatch => {
    const { username, userId } = formData;
    
    // Reference to the user document in the Firestore database
    const userDoc = doc(db, "users", userId);

    console.log('UPDATING USERNAME');

    // Try block to handle the update operation
    try {

        // Log the user username data received
        console.log('USER USERNAME DATA')

        // Check if Username is taken
        console.log("CHECKING USERNAME")

        // Create a query to check if the username already exists in the users collection
        const usernameQuery = query (usersCollectionRef, where("username", "==", username))

        // Retrieve the list of users with the same username
        const usernameArray = await getDocs(usernameQuery);

        if(usernameArray.docs.length > 0) {

            // The username is already taken, block the update and alert the user
            dispatch(setAlert('That username has been taken.', 'danger'));
        } else {

            // The username is available, proceed with the update
            await updateDoc(userDoc, {
                username: username.toLowerCase()
            })

            // --- UPDATE ALL USER POSTS ---

            const userPostsQuery = query(postsCollectionRef, where("user._id", "==", userId));
            const userPosts = await getDocs(userPostsQuery);

            for (const doc of userPosts.docs) {

                const userPost = await getDoc(doc.ref);

                // --- UPDATE USER COMMENTS ---
            
                const commentsCollectionRef = collection(doc.ref, "comments")

                // Create a query to fetch the comments
                const q = query(commentsCollectionRef);

                // Fetch the comment data
                const commentData = await getDocs(q);
                
                for (const commentDoc of commentData.docs) {

                    if(commentDoc.data().user._id === auth.currentUser.uid) {

                        await updateDoc(commentDoc.ref, {
                            user: {
                                ...commentDoc.data().user,
                                username
                            },
                        });
                    }
                }

                // END UPDATE COMMENTS

                await updateDoc(doc.ref, {
                    user: {
                        ...userPost.data().user,
                        username
                    },
                });

            }

            // END UPDATE POSTS

            // --- UPDATE USER CHATS ---

            const userChatsQuery = query(chatsCollectionRef, where("current_users", "array-contains", auth.currentUser.uid));
            const userChats = await getDocs(userChatsQuery);

            for (const chatDoc of userChats.docs) {

                const userChat = await getDoc(chatDoc.ref);

                // Check if the authenticated user is the recipient of the chat (to_user)
                if(auth.currentUser.uid === userChat.data().to_user._id) {
                    
                    await updateDoc(chatDoc.ref, {
                        ...chatDoc.data(),
                        to_user: {
                            ...chatDoc.data().to_user,
                            username
                        },
                    });
                } 
                // Check if the authenticated user is the sender of the chat (from_user)
                else if (auth.currentUser.uid === chatDoc.data().from_user._id) {
                    
                    await updateDoc(chatDoc.ref, {
                        ...chatDoc.data(),
                        from_user: {
                            ...chatDoc.data().from_user,
                            username
                        },
                    });
                }

            }

            // END UPDATE CHATS

            // Get the updated User data for display
            const updatedUserDoc = await getDoc(userDoc);

            console.log('GOT UPDATED USER BY ID');
    
            dispatch({
                type: UPDATE_AUTH,

                // Updated user data
                payload: updatedUserDoc.data()
            });

            // Dispatch a success alert indicating that the profile has been updated
            dispatch(setAlert('Profile Updated', 'success'));
        }

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        if(err.response) {
            const errors = err.response.data.errors;

            // Dispatch alerts for each error received from the server
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        } else {

            // Display a generic error message if no specific error response is received
            setAlert('Something went wrong', 'danger')
        }
    }
}

// --- STRIPE INFO ----

// Add User's Stripe Id
export const updateStripe = (accountID) => async dispatch => {

    // Reference to the user document in the Firestore database
    const userDoc = doc(db, "users", auth.currentUser.uid);

    console.log('UPDATING USER STRIPE ID')

    // Try block to handle the update operation
    try {

        // Update the user's birth date info
        await updateDoc(userDoc, {
            stripe_account_id: accountID,
            charges_enabled: false,
            payouts_enabled: false,
            stripe_onboarding_complete: false
        })

        // Get updated User data for display
        const updatedUserDoc = await getDoc(userDoc);

        console.log('GOT UPDATED USER BY ID');
  
        // Dispatch an action to update the authenticated user's information with the updated data
        dispatch({
            type: UPDATE_AUTH,
            payload: updatedUserDoc.data()
        });

        // Dispatch a success alert indicating that the profile has been updated
        dispatch(setAlert('Profile Updated', 'success'));

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        if(err.response) {
            const errors = err.response.data.errors;

            // Dispatch alerts for each error received from the server
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        } else {

            // Display a generic error message if no specific error response is received
            setAlert('Something went wrong', 'danger')
        }
    }
}

// Update User's Stripe charges_enabled field
export const update_charges_enabled = (charges_enabled) => async dispatch => {

    // Reference to the user document in the Firestore database
    const userDoc = doc(db, "users", auth.currentUser.uid);

    console.log('UPDATING USER CHARGES ENABLED')

    // Try block to handle the update operation
    try {

        // Update the user's birth date info
        await updateDoc(userDoc, {
            charges_enabled
        })

        // Get updated User data for display
        const updatedUserDoc = await getDoc(userDoc);

        console.log('GOT UPDATED USER BY ID');
  
        // Dispatch an action to update the authenticated user's information with the updated data
        dispatch({
            type: UPDATE_AUTH,
            payload: updatedUserDoc.data()
        });

        // Dispatch a success alert indicating that the profile has been updated
        dispatch(setAlert('Profile Updated', 'success'));

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        if(err.response) {
            const errors = err.response.data.errors;

            // Dispatch alerts for each error received from the server
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        } else {

            // Display a generic error message if no specific error response is received
            setAlert('Something went wrong', 'danger')
        }
    }
}

// Update User's Stripe payouts_enabled field
export const update_payouts_enabled = (payouts_enabled) => async dispatch => {

    // Reference to the user document in the Firestore database
    const userDoc = doc(db, "users", auth.currentUser.uid);

    console.log('UPDATING USER PAYOUTS_ENABLED')

    // Try block to handle the update operation
    try {

        // Update the user's info
        await updateDoc(userDoc, {
            payouts_enabled
        })

        // Get updated User data for display
        const updatedUserDoc = await getDoc(userDoc);

        console.log('GOT UPDATED USER BY ID');
  
        // Dispatch an action to update the authenticated user's information with the updated data
        dispatch({
            type: UPDATE_AUTH,
            payload: updatedUserDoc.data()
        });

        // Dispatch a success alert indicating that the profile has been updated
        dispatch(setAlert('Profile Updated', 'success'));

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        if(err.response) {
            const errors = err.response.data.errors;

            // Dispatch alerts for each error received from the server
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        } else {

            // Display a generic error message if no specific error response is received
            setAlert('Something went wrong', 'danger')
        }
    }
}

// Update User's Stripe stripe_onboarding_complete field
export const update_stripe_onboarding_complete = (details_submitted) => async dispatch => {

    // Reference to the user document in the Firestore database
    const userDoc = doc(db, "users", auth.currentUser.uid);

    console.log('UPDATING USER ONBOARDING_COMPLETE FIELD')

    // Try block to handle the update operation
    try {

        // Update the user info
        await updateDoc(userDoc, {
            stripe_onboarding_complete: details_submitted
        })

        // Get updated User data for display
        const updatedUserDoc = await getDoc(userDoc);

        console.log('GOT UPDATED USER BY ID');
  
        // Dispatch an action to update the authenticated user's information with the updated data
        dispatch({
            type: UPDATE_AUTH,
            payload: updatedUserDoc.data()
        });

        // Dispatch a success alert indicating that the profile has been updated
        dispatch(setAlert('Profile Updated', 'success'));

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        if(err.response) {
            const errors = err.response.data.errors;

            // Dispatch alerts for each error received from the server
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        } else {

            // Display a generic error message if no specific error response is received
            setAlert('Something went wrong', 'danger')
        }
    }
}

// --- END: STRIPE INFO -----

// Update auth inputs
export const changeUserPhone = (formData, go_to_checkout) => async dispatch => {
    const { phone, userId } = formData;

    // Reference to the user document in the Firestore database
    const userDoc = doc(db, "users", userId);

    console.log('UPDATING USER PHONE');

    // Try block to handle the update operation
    try {

        // Log the user's phone data received
        console.log('USER PHONE DATA')
        
        // Update the user's phone number
        await updateDoc(userDoc, {
            phone
        })

        // Get updated User data for display
        const updatedUserDoc = await getDoc(userDoc);

        console.log('GOT UPDATED USER BY ID');
  
        // Dispatch an action to update the authenticated user's information with the updated data
        dispatch({
            type: UPDATE_AUTH,
            payload: updatedUserDoc.data()
        });

        // dispatch({
        //     type: UPDATE_AUTH,
        //     payload: res.data
        // });

        // Dispatch a success alert indicating that the profile has been updated
        dispatch(setAlert('Profile Updated', 'success'));

        if(go_to_checkout) {
            window.location = '/checkout'
        }

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        if(err.response) {
            const errors = err.response.data.errors;

            // Dispatch alerts for each error received from the server
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        } else {

            // Display a generic error message if no specific error response is received
            setAlert('Something went wrong', 'danger')
        }
    }
}

// Update auth inputs
export const changeUserGender = (formData) => async dispatch => {
    const { gender, userId } = formData;

    // Reference to the user document in the Firestore database
    const userDoc = doc(db, "users", userId);

    console.log('UPDATING USER GENDER')

    // Try block to handle the update operation
    try {

        // Log the user's gender data received
        console.log('USER GENDER DATA')

        // Update the user's gender
        await updateDoc(userDoc, {
            gender
        })

        // Get updated User data for display
        const updatedUserDoc = await getDoc(userDoc);

        console.log('GOT UPDATED USER BY ID');
  
        // Dispatch an action to update the authenticated user's information with the updated data
        dispatch({
            type: UPDATE_AUTH,
            payload: updatedUserDoc.data()
        });
        
        // dispatch({
        //     type: UPDATE_AUTH,
        //     payload: res.data
        // });

        // Dispatch a success alert indicating that the profile has been updated
        dispatch(setAlert('Profile Updated', 'success'));

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        if(err.response) {
            const errors = err.response.data.errors;

            // Dispatch alerts for each error received from the server
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        } else {

            // Display a generic error message if no specific error response is received
            setAlert('Something went wrong', 'danger')
        }
    }
}

// Update auth inputs
export const changeUserBirthDate = (formData) => async dispatch => {
    const { month, day, year, userId } = formData;

    // Reference to the user document in the Firestore database
    const userDoc = doc(db, "users", userId);

    console.log('UPDATING USER BIRTH DATE')

    // Try block to handle the update operation
    try {

        // Log the user's birth date data received
        console.log('USER BIRTHDAY')

        // Update the user's birth date info
        await updateDoc(userDoc, {
            birth_date: {
                month,
                day,
                year,
            },
        })

        // Get updated User data for display
        const updatedUserDoc = await getDoc(userDoc);

        console.log('GOT UPDATED USER BY ID');
  
        // Dispatch an action to update the authenticated user's information with the updated data
        dispatch({
            type: UPDATE_AUTH,
            payload: updatedUserDoc.data()
        });

        // dispatch({
        //     type: UPDATE_AUTH,
        //     payload: res.data
        // });

        // Dispatch a success alert indicating that the profile has been updated
        dispatch(setAlert('Profile Updated', 'success'));

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        if(err.response) {
            const errors = err.response.data.errors;

            // Dispatch alerts for each error received from the server
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        } else {

            // Display a generic error message if no specific error response is received
            setAlert('Something went wrong', 'danger')
        }
    }
}

// Update user bio
export const editUserBio = (formData) => async dispatch => {
    const { bio, userId } = formData;

    // Reference to the user document in the Firestore database
    const userDoc = doc(db, "users", userId);

    console.log('UPDATING USER BIO')

    // Try block to handle the update operation
    try {

        // Log the user's bio data received
        console.log('UPDATING USER BIO')

        // Update the user's bio
        await updateDoc(userDoc, {
            bio: bio,
            profile_set_up: true
        })

        // Get updated User data for display
        const updatedUserDoc = await getDoc(userDoc);

        console.log('GOT UPDATED USER BY ID');
  
        // Dispatch an action to update the authenticated user's information with the updated data
        dispatch({
            type: UPDATE_AUTH,
            payload: updatedUserDoc.data()
        });

        // Dispatch a success alert indicating that the profile has been updated
        dispatch(setAlert('Profile Updated', 'success'));

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        if(err.response) {
            const errors = err.response.data.errors;

            // Dispatch alerts for each error received from the server
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        } else {

            // Display a generic error message if no specific error response is received
            setAlert('Something went wrong', 'danger')
        }
    }
}

// Update auth inputs
export const changePassword = (formData) => async dispatch => {
    const { password, currentPassword, userId } = formData;
    console.log('UPDATING PASSWORD');

    // Try block to handle the update operation
    try {

        // Log the user's ID info received - NOT NEEDED
        console.log('FRONTEND ID UPDATE');

        // Update the user's password
        await updatePassword(auth.currentUser, password);

        console.log('UPDATE PASSWORD SUCCESS');

        // dispatch({
        //     type: UPDATE_AUTH,
        //     payload: res.data
        // });

        // Dispatch a success alert indicating that the profile has been updated
        dispatch(setAlert('Profile Updated', 'success'));

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        if(err.response) {
            const errors = err.response.data.errors;

            // Dispatch alerts for each error received from the server
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        } else {

            // Display a generic error message if no specific error response is received
            setAlert('Something went wrong', 'danger')
        }
    }
}

// Update auth inputs
export const changeUserEmail = (formData) => async dispatch => {
    const { email, userId } = formData;

    // Reference to the user document in the Firestore database
    const userDoc = doc(db, "users", userId);

    // Try block to handle the update operation
    try {

        // Log the user's email data and ID received
        console.log('FRONTEND EMAIL UPDATE');
        console.log('FRONTEND ID UPDATE');

        
        // Update the user's email in the authentication system
        await updateEmail(auth.currentUser, email);
        
        // Update the user's email in the Firestore database
        await updateDoc(userDoc, {
            email: email
        })

        console.log('UPDATE EMAIL SUCCESS');

        // Get updated User data for display
        const updatedUserDoc = await getDoc(userDoc);

        console.log('GOT UPDATED USER BY ID');
  
        // Dispatch an action to update the authenticated user's information with the updated data
        dispatch({
            type: UPDATE_AUTH,
            payload: updatedUserDoc.data()
        });

        // dispatch({
        //     type: UPDATE_AUTH,
        //     payload: res.data
        // });

        // Dispatch a success alert indicating that the profile has been updated
        dispatch(setAlert('Profile Updated', 'success'));

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        // Display a generic error message if no specific error response is received
        setAlert('Something went wrong', 'danger');
    }
}

// Remove current user info from state
export const clearUser = () => dispatch => {
    dispatch({
        type: CLEAR_USER
    });

}

// Profile update loading
export const setProfileUpdateLoading = () => {
    return {
        type: PROFILE_UPDATE_LOADING
    }
}

// Profile update loading
export const setProfileUpdateLoaded = () => {
    return {
        type: PROFILE_UPDATE_LOADED
    }
}

// Account creation loading
export const setAccountLoading = () => {
    return {
        type: ACCOUNT_LOADING
    }
}

// Account creation loading
export const setAccountLoadingDone = () => {
    return {
        type: ACCOUNT_LOADING_DONE
    }
}

// ---------------------------------------- //
// ------- BULK UPDATE In DB -------------
// ----------------------------------------

// ADD OU campus_id to all current users
export const add_campus_id_to_all_users = () => async dispatch => {

    console.log('ADDING CAMPUS ID TO USERS...');

    // Try block to handle the update operation
    try {
  
        console.log('GETTING ALL USERS')

        // Retrieve all documents from the 'users' collection
        const data = await getDocs(usersCollectionRef);

        // Create a user list by mapping each document to an object and including the document ID
        const userList = data.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('SHOW USER LIST')

        // Loop through the user list and update each document with the 'campus_id' field
        userList.forEach(async (user) => {

            // Reference to the user document in the Firestore database
            const userDoc = doc(db, "users", user._id);

            try {
                console.log('ADDING FIELD')
                // Update the user doc by adding the campus_id field
                await updateDoc(userDoc, {
                    campus_id: 'b7nqQGbbbzw08uh0MoPA',
                })
            } catch (error) {
                console.log('ERROR UPDATING USER');
                console.log(error)
            }

        });

        // Dispatch a success alert indicating that the profile has been updated
        dispatch(setAlert('DONE Updating Users!', 'success'));

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        // Display a generic error message if no specific error response is received
        setAlert('Something went wrong', 'danger')
    }
}

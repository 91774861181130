import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Blurhash } from 'react-blurhash';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import { format, parseISO, fromUnixTime, formatDistanceToNow } from 'date-fns';

// Firebase
import { db } from '../../config/firebase-config';
import { collection, getDocs, getDoc, doc, query, where } from 'firebase/firestore';

// Actions

// Components

// Icons = material UI

// Modal

const Icon_Block = ({ 
    detail_Obj,
    page
}) => {

    return (
        <div onClick={() => window.location.href = `/game/${detail_Obj._id}`} style={{marginRight: '20px', marginBottom: `${page !== 'detail' ? '20px' : '0'}`, display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer'}}>
            <div style={{width: `${page !== 'detail' ? '106px' : '72px'}`, height: `${page !== 'detail' ? '106px' : '72px'}`, borderRadius: '8px', overflow: 'hidden'}}>
                <img style={{width: '100%', height: '100%'}} src={detail_Obj.icon_link} />
            </div>

            {page !== "detail" && (
                <div style={{paddingTop: '8px', display: 'flex', fontSize: '14px', lineHeight: '18px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {detail_Obj.title}
                </div>
            )}
        </div>
    )
}


Icon_Block.propTypes = {

}

// Map these states from Redux store to the component props
const mapStateToProps = state => ({

})

// Connect the Icon_Block component to the Redux store and export it
export default connect(mapStateToProps, { 

})(Icon_Block);


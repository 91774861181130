// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Auth
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const PROFILE_UPDATE_LOADING = 'PROFILE_UPDATE_LOADING';
export const PROFILE_UPDATE_LOADED = 'PROFILE_UPDATE_LOADED';
export const ACCOUNT_LOADING = 'ACCOUNT_LOADING';
export const ACCOUNT_LOADING_DONE = 'ACCOUNT_LOADING_DONE';
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';
export const UPDATE_AUTH = 'UPDATE_AUTH';
export const UPDATE_USER_IMG = 'UPDATE_USER_IMG';
export const AUTH_ERROR = 'AUTH_ERROR';
export const USER_ERROR = 'USER_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const SET_SIDEBAR = 'SET_SIDEBAR';
export const CLEAR_USER = 'CLEAR_USER';

// Nav
export const SET_NAV1 = 'SET_NAV1';
export const SET_NAV2 = 'SET_NAV2';
export const SET_NAV3 = 'SET_NAV3';
export const REMOVE_NAV1 = 'REMOVE_NAV1';
export const REMOVE_NAV2 = 'REMOVE_NAV2';
export const REMOVE_NAV3 = 'REMOVE_NAV3';
export const SET_PAGE = 'SET_PAGE';
export const TOGGLE_USER_MODAL = 'TOGGLE_USER_MODAL';
export const TOGGLE_PROFILE_MODAL = 'TOGGLE_PROFILE_MODAL';
export const TOGGLE_AUTH_MODAL = 'TOGGLE_AUTH_MODAL';
export const SET_AUTH_MODAL = 'SET_AUTH_MODAL';
export const TOGGLE_VERIFY_SCHOOL_MODAL = 'TOGGLE_VERIFY_SCHOOL_MODAL';
export const SET_PROFILE_MODAL = 'SET_PROFILE_MODAL';
export const TOGGLE_POST_MODAL = 'TOGGLE_POST_MODAL';
export const SET_POST_MODAL = 'SET_POST_MODAL';
export const TOGGLE_COMMENT_MODAL = 'TOGGLE_COMMENT_MODAL';
export const SET_COMMENT_MODAL = 'SET_COMMENT_MODAL';
export const TOGGLE_SHARE_MODAL = 'TOGGLE_SHARE_MODAL';
export const SET_MAIN_NAV = 'SET_MAIN_NAV';
export const TOGGLE_SIDE_NAV = 'TOGGLE_SIDE_NAV';
export const TOGGLE_ITEM_MODAL = 'TOGGLE_ITEM_MODAL';
export const TOGGLE_COLLECTION_MODAL = 'TOGGLE_COLLECTION_MODAL';
export const TOGGLE_LOCATION_MODAL = 'TOGGLE_LOCATION_MODAL';
export const REMOVE_ITEM_MODAL = 'REMOVE_ITEM_MODAL';
export const REMOVE_COLLECTION_MODAL = 'REMOVE_COLLECTION_MODAL';
export const REMOVE_LOCATION_MODAL = 'REMOVE_LOCATION_MODAL';
export const SET_SCROLL_TO_TOP = 'SET_SCROLL_TO_TOP';
// Marketplace  
export const SET_IS_MARKETPLACE = 'SET_IS_MARKETPLACE';  
// Driver Portal
export const TOGGLE_QUIT_DELIV_MODAL = 'TOGGLE_QUIT_DELIV_MODAL'; 
export const TOGGLE_START_DELIV_MODAL = 'TOGGLE_START_DELIV_MODAL'; 
export const TOGGLE_CONFIRM_PICKUP_MODAL = 'TOGGLE_CONFIRM_PICKUP_MODAL'; 
export const TOGGLE_CUSTOMER_CODE_MODAL = 'TOGGLE_CUSTOMER_CODE_MODAL'; 
// Campus Logic
export const SET_CAMPUS_ID = 'SET_CAMPUS_ID'; 
// Security Logic
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const SET_FIREWALL = 'SET_FIREWALL'; 
    

// Posts
export const GET_POSTS = 'GET_POSTS';
export const SET_POSTS = 'SET_POSTS';
export const SET_LAST_POSTS = 'SET_LAST_POSTS';
export const GET_POST = 'GET_POST';
export const UPDATE_POST_LIKES = 'UPDATE_POST_LIKES';
export const ADD_POST = 'ADD_POST';
export const DELETE_POST = 'DELETE_POST';
export const GET_COMMENTS = 'GET_COMMENTS';
export const SET_COMMENTS = 'SET_COMMENTS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const COMMENTS_LOADING = 'COMMENTS_LOADING';
export const POSTS_LOADING = 'POSTS_LOADING';
export const CLEAR_POSTS = 'CLEAR_POSTS';
export const CLEAR_POST = 'CLEAR_POST';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_EDITOR_TEXT = 'UPDATE_EDITOR_TEXT';
export const CLEAR_EDITOR_TEXT = 'CLEAR_EDITOR_TEXT';
export const UPDATE_EDITOR_CATEGORY = 'UPDATE_EDITOR_CATEGORY';
export const CLEAR_EDITOR_CATEGORY = 'CLEAR_EDITOR_CATEGORY';
export const UPDATE_EDITOR_FILES = 'UPDATE_EDITOR_FILES';
export const REMOVE_EDITOR_FILE = 'REMOVE_EDITOR_FILE';
export const CLEAR_EDITOR_FILES = 'CLEAR_EDITOR_FILES';
export const UPDATE_URLS = 'UPDATE_URLS';
export const REMOVE_URL = 'REMOVE_URL';
export const CLEAR_URLS = 'CLEAR_URLS';
export const UPDATE_LAST_POST_ADDED = 'UPDATE_LAST_POST_ADDED';
export const POST_UPLOADING = 'POST_UPLOADING';
export const POST_UPLOADING_IMGS = 'POST_UPLOADING_IMGS';
export const SET_SHOW_BOTTOM_SPINNER = 'SET_SHOW_BOTTOM_SPINNER';
export const SET_LAST_PAGE_DOC = 'SET_LAST_PAGE_DOC';
export const SET_NO_MORE_POSTS = 'SET_NO_MORE_POSTS';

// Chats
export const CHATS_LOADING = 'CHATS_LOADING';
export const CHAT_LOADING = 'CHAT_LOADING';
export const GET_CHAT = 'GET_CHAT';
export const GET_CHATS = 'GET_CHATS';
export const CREATE_CHAT = 'CREATE_CHAT';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const GET_MESSAGES = 'GET_MESSAGES';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_MESSAGE = 'SET_MESSAGE';
export const UPDATE_LAST_MESSAGE = 'UPDATE_LAST_MESSAGE';
export const SET_UNREAD_CHATS = 'SET_UNREAD_CHATS';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const CHAT_ERROR = 'CHAT_ERROR';
export const CLEAR_CHAT = 'CLEAR_CHAT';
export const CLEAR_CHATS = 'CLEAR_CHATS';
export const UPDATE_EDITOR_IMG_FILE = 'UPDATE_EDITOR_IMG_FILE';
export const CLEAR_EDITOR_IMG_FILE = 'CLEAR_EDITOR_IMG_FILE';
export const UPDATE_IMG_URL = 'UPDATE_IMG_URL';
export const REMOVE_IMG_URL = 'REMOVE_IMG_URL';

// Notifications
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_LAST_NOTIFICATIONS = 'SET_LAST_NOTIFICATIONS';
export const GET_ACTIVITY = 'GET_ACTIVITY';
export const SET_UNREAD_COUNT = 'SET_UNREAD_COUNT';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING';
    
// Searches
export const GET_RECENT_SEARCHES = 'GET_RECENT_SEARCHES';
export const GET_POPULAR_SEARCHES = 'GET_POPULAR_SEARCHES';
export const SEARCHES_ERROR = 'SEARCHES_ERROR';
export const CLEAR_POPULAR_SEARCHES = 'CLEAR_POPULAR_SEARCHES';
export const POPULAR_SEARCHES_LOADING = 'POPULAR_SEARCHES_LOADING';
export const CLEAR_RECENT_SEARCHES = 'CLEAR_RECENT_SEARCHES';
export const RECENT_SEARCHES_LOADING = 'RECENT_SEARCHES_LOADING';
export const DELETE_RECENT_SEARCH = 'DELETE_RECENT_SEARCH';

// Games
export const GAMES_LOADING = 'GAMES_LOADING';
export const GET_GAMES = 'GET_GAMES';
export const SET_GAMES = 'SET_GAMES';
export const SET_LAST_GAMES = 'SET_LAST_GAMES';
export const GET_GAME = 'GET_GAME';
export const UPDATE_GAME_LIKES = 'UPDATE_GAME_LIKES';
export const ADD_GAME = 'ADD_GAME';
export const DELETE_GAME = 'DELETE_GAME';
export const GAME_COMMENTS_LOADING = 'GAME_COMMENTS_LOADING';
export const GET_GAME_COMMENTS = 'GET_GAME_COMMENTS';
export const SET_GAME_COMMENTS = 'SET_GAME_COMMENTS';
export const ADD_GAME_COMMENT = 'ADD_GAME_COMMENT';
export const REMOVE_GAME_COMMENT = 'REMOVE_GAME_COMMENT';
export const CLEAR_GAMES = 'CLEAR_GAMES';
export const CLEAR_GAME = 'CLEAR_GAME';
export const GAME_ERROR = 'GAME_ERROR';
export const UPDATE_LAST_GAME_ADDED = 'UPDATE_LAST_GAME_ADDED';
export const GAME_UPLOADING = 'GAME_UPLOADING';
export const GAME_UPLOADING_IMGS = 'GAME_UPLOADING_IMGS';
export const SET_SHOW_BOTTOM_SPINNER_GAME = 'SET_SHOW_BOTTOM_SPINNER_GAME'; 
export const SET_LAST_PAGE_DOC_GAME = 'SET_LAST_PAGE_DOC_GAME'; 
export const SET_NO_MORE_GAMES = 'SET_NO_MORE_GAMES';

// Projects
export const PROJECTS_LOADING = 'PROJECTS_LOADING';
export const GET_PROJECTS = 'GET_PROJECTS';
export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_LAST_PROJECTS = 'SET_LAST_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const UPDATE_PROJECT_LIKES = 'UPDATE_PROJECT_LIKES';
export const ADD_PROJECT = 'ADD_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const PROJECT_COMMENTS_LOADING = 'PROJECT_COMMENTS_LOADING';
export const GET_PROJECT_COMMENTS = 'GET_PROJECT_COMMENTS';
export const SET_PROJECT_COMMENTS = 'SET_PROJECT_COMMENTS';
export const ADD_PROJECT_COMMENT = 'ADD_PROJECT_COMMENT';
export const REMOVE_PROJECT_COMMENT = 'REMOVE_PROJECT_COMMENT';
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';
export const CLEAR_PROJECT = 'CLEAR_PROJECT';
export const PROJECT_ERROR = 'PROJECT_ERROR';
export const UPDATE_LAST_PROJECT_ADDED = 'UPDATE_LAST_PROJECT_ADDED';
export const PROJECT_UPLOADING = 'PROJECT_UPLOADING';
export const PROJECT_UPLOADING_IMGS = 'PROJECT_UPLOADING_IMGS';
export const SET_SHOW_BOTTOM_SPINNER_PROJECT = 'SET_SHOW_BOTTOM_SPINNER_PROJECT'; 
export const SET_LAST_PAGE_DOC_PROJECT = 'SET_LAST_PAGE_DOC_PROJECT'; 
export const SET_NO_MORE_PROJECTS = 'SET_NO_MORE_PROJECTS';

import React from 'react'
import PropTypes from 'prop-types'

const Readme = props => {
    return (
        <div className='readme_body'>
            <main>
        <h1 id="snake-game-🐍">Snake Game 🐍</h1>
<p>A classic Snake game built using <strong>Python</strong> and <strong>Pygame</strong>. The objective is to control the snake, eat the apple, and grow in size while avoiding collisions with walls or the snake's own body.</p>
<p><img src="https://github.com/tunde262/snake_game_python/blob/main/assets/thumbnail.png?raw=true" alt="thumbnail" /></p>

<h2 id="features">Features</h2>
<ul>
<li><strong>Checkerboard Background:</strong> A classic green checkerboard-style grid.</li>
<li><strong>Dynamic Snake Movement:</strong> Smooth transitions and growth with each apple consumed.</li>
<li><strong>Score Tracking:</strong> Displays the current score on the screen.</li>
<li><strong>Collision Detection:</strong> Walls, body, and apple collisions are handled seamlessly.</li>
<li><strong>Pause Functionality:</strong> Pause and resume the game with ease.</li>
</ul>
<hr />
<h2 id="how-to-play">How to Play</h2>
<ol>
<li>The game begins with the snake in a stationary state.</li>
<li>Use the arrow keys to control the snake's movement.</li>
<li>Eat the red apple to grow in size and increase your score.</li>
<li>Avoid running into walls or your own body.</li>
<li>Press <code>P</code> to pause the game at any time.</li>
</ol>
<hr />
<h2 id="requirements">Requirements</h2>
<ul>
<li>Python 3.9 or higher</li>
<li>Pygame library</li>
<li>(Optional) Pygbag for web hosting</li>
</ul>
<hr />
<h2 id="installation">Installation</h2>
<ol>
<li><p><strong>Clone the repository</strong>:</p>
<pre><code class="hljs bash language-bash">git <span class="hljs-built_in">clone</span> https://github.com/tunde262/snake_game_python.git
<span class="hljs-built_in">cd</span> snake_game_python</code></pre></li>
<li><p><strong>Set up a virtual environment</strong> (optional but recommended):</p>
<pre><code class="hljs bash language-bash">python -m venv venv
<span class="hljs-built_in">source</span> venv/bin/activate  <span class="hljs-comment"># On Windows: venv\Scripts\activate</span></code></pre></li>
<li><p><strong>Install dependencies</strong>:</p>
<pre><code class="hljs bash language-bash">pip install pygame</code></pre></li>
<li><p><strong>Run the game</strong>:<br />
<code>bash
python snake.py
</code></p></li>
</ol>
<hr />
<h2 id="game-controls">Game Controls</h2>
<ul>
<li><code>↑</code> - Move Up</li>
<li><code>↓</code> - Move Down</li>
<li><code>→</code> - Move Right</li>
<li><code>←</code> - Move Left</li>
<li><code>P</code> - Pause</li>
</ul>
<hr />
<h2 id="future-enhancements">Future Enhancements</h2>
<ul>
<li>Add difficulty levels (Easy, Medium, Hard).</li>
<li>Introduce obstacles as the game progresses.</li>
<li>Implement a leaderboard to track high scores.</li>
<li>Add sound effects and background music.</li>
<li>Mobile-friendly version with touch controls.</li>
<li>Add on-screen instructions.</li>
</ul>
<hr />
<h3 id="author">Author</h3>
<p><em>Tunde Adepitan</em>  <br />
GitHub: <a href="https://github.com/tunde262">tunde262</a>  <br />
Feel free to connect and give me feedback!</p>
            </main>
        </div>
  )
}

Readme.propTypes = {}

export default Readme;
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Firebase

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { setPage, add_To_Recent_History } from '../actions/navActions';

// Layout Wrapper
import Layout from '../components/layout/Layout';

// Components
import HowItWorks from '../components/common/HowItWorks';

// Modal

const AboutPage = ({ 
    setPage, 
    add_To_Recent_History
}) => {

    // State variable to track if Mixpanel event has been sent
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    useEffect(() => {

        // Set the current page to 'likes'
        setPage('Resale About');
        add_To_Recent_History(url.pathname);  // current url 

    }, []);

    // Handles Mixpanel tracking
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

        // Track "About Page View" event
        mixpanel.track("About Page View");
    }

    // Checks if the environment is production and Mixpanel tracking hasn't been sent
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {

        // Call Mixpanel tracking function
        handleMixpanel();

        // Set Mixpanel event as sent
        setSentMixpanel(true);
    }

    return (
        <Fragment>
            <Layout page="home">
                <div className="feed_headerTabs">
                    <Link to="/home">
                        <div>
                            <h3>Feed</h3>
                            <div className="block__underline"></div>
                        </div>
                    </Link>
                    <Link to="/media">
                        <div>
                            <h3>Media</h3>
                            <div className="block__underline"></div>
                        </div>
                    </Link>
                    <Link to="/about">
                        <div className="active">
                            <h3>About</h3>
                            <div className="block__underline"></div>
                        </div>
                    </Link>
                </div>

                <div className="post noHighlight">
                    <HowItWorks />
                </div>

                {/* Add space to the bottom of the page b/c of auth alert  */}
                <div style={{height: '200px'}}></div>
            </Layout>

        </Fragment>
    )
}

AboutPage.propTypes = {
    setPage: PropTypes.func.isRequired, // Function prop to set the current page in the navigation
    add_To_Recent_History: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    setPage, 
    add_To_Recent_History
})(AboutPage);

import React from 'react'
import PropTypes from 'prop-types'

const Readme = props => {
    return (
        <div className='readme_body'>
            <main>
        <h1 id="pong-game-with-pygame">PONG Game with Pygame</h1>
<p>A classic <strong>PONG</strong> game built using <strong>Pygame</strong>. Includes 2-player local gameplay, paddle controls, a scoring system, and collision detection. The program also includes optional web hosting support using <strong>asyncio</strong>.</p>
<p><img src="https://github.com/tunde262/pong_game_python/blob/main/assets/thumbnail.png?raw=true" alt="thumbnail" /></p>
<h2 id="features">Features</h2>
<ul>
<li>Classic <strong>PONG</strong> gameplay for two players.</li>
<li>Smooth paddle and ball movement with realistic collision mechanics.</li>
<li>Scoring system displayed on the screen.</li>
<li>Restart and play functionalities using keyboard controls.</li>
<li>Optimized to run at <strong>60 FPS</strong> for smooth visuals.</li>
<li><strong>Optional Web Hosting</strong> on the web using asyncio compatibility with the Pygbag library..</li>
</ul>
<hr />
<h2 id="requirements">Requirements</h2>
<ul>
<li>Python 3.8+, recommended for the following reasons:<ol>
<li><strong>Enhanced <code>asyncio</code> Support</strong>: The project uses <code>asyncio.run()</code> for the optional web-hosting feature, which simplifies asynchronous execution.</li>
<li><strong>Compatibility with Pygbag</strong>: Pygbag, used for web hosting, requires Python 3.8+.</li></ol></li>
<li>Pygame (install via <code>pip install pygame</code>)</li>
<li>(Optional) Pygbag for web hosting</li>
</ul>
<h2 id="installation">Installation</h2>
<ol>
<li><strong>Clone the Repository</strong>:</li>
</ol>
<pre><code class="hljs bash language-bash">   git <span class="hljs-built_in">clone</span> https://github.com/tunde262/pong_game_python.git
   <span class="hljs-built_in">cd</span> pong_game_python</code></pre>
<ol start="3">
<li><strong>Install Dependencies</strong>: Ensure you have Python 3.8+ installed. Then, install the required libraries:</li>
</ol>
<pre><code class="hljs bash language-bash">   pip install pygame</code></pre>
<ol start="4">
<li><strong>Run the Game</strong>:</li>
</ol>
<pre><code class="hljs bash language-bash">   python main.py</code></pre>
<hr />
<h2 id="web-hosting-optional">Web Hosting (Optional)</h2>
<p>This project supports optional web hosting using <code>asyncio</code> and the <strong>Pygbag</strong> library.</p>
<ol>
<li><strong>Install the Pygbag library</strong>:</li>
</ol>
<pre><code class="hljs bash language-bash">   pip install pygbag</code></pre>
<ol start="2">
<li><strong>Compile and host the game</strong>:</li>
</ol>
<pre><code class="hljs bash language-bash">   pygbag main.py</code></pre>
<ol start="3">
<li>Follow the output instructions to serve the game in a browser.</li>
</ol>
<h2 id="how-to-play">How to Play</h2>
<ul>
<li>The ball moves at an initial angle, bouncing off paddles and walls.</li>
<li>The game detects goals, restarts the ball, and updates the score.</li>
<li>Players can control paddles smoothly with clamping to keep paddles within the screen.</li>
<li>The screen updates in real time, maintaining a 60 FPS frame rate for smooth play.</li>
</ul>
<h2 id="game-controls">Game Controls</h2>
<ul>
<li><code>P</code> Key: Start the game and move the ball.</li>
<li><code>R</code> Key: Restart the game.</li>
<li>Player 1 Controls:<ul>
<li><code>W</code> Key: Move paddle up.</li>
<li><code>S</code> Key: Move paddle down.</li></ul></li>
<li>Player 2 Controls:<ul>
<li><code>UP</code> Arrow: Move paddle up.</li>
<li><code>DOWN</code> Arrow: Move paddle down.</li></ul></li>
</ul>
<hr />
<h2 id="future-enhancements">Future Enhancements</h2>
<ul>
<li>Add an AI competitor.</li>
<li>Mobile-friendly version with touch controls.</li>
<li>Add on-screen instructions.</li>
</ul>
<hr />
<h3 id="author">Author</h3>
<p><em>Tunde Adepitan</em>  <br />
GitHub: <a href="https://github.com/tunde262">tunde262</a>  <br />
Feel free to connect and give me feedback!</p>
            </main>
        </div>
  )
}

Readme.propTypes = {}

export default Readme;
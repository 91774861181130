import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types'

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { getUserProjects } from '../../actions/projectActions';

// components - material UI
import { Button, Link } from '@material-ui/core';
import Spinner from '../common/Spinner';
import Project_Block from '../../pages/GamePage/Project_Block';

// icons - material UI
import InstagramIcon from '@material-ui/icons/Instagram';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Projects_Page = ({ 
    handleSlideMenu, 
    auth,
    chat,
    notification,
    project,
    getUnreadChats,
    getUnreadCount,
    history,
    campus_name,
    campus_id,
    goBack,
    getUserProjects
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    // Projects
    const [projectList, setProjectList] = useState([]);
    const [gotProjects, setGotProjects] = useState(false);

    // Temporarily disable to load more function to avoid duplicate fetch requests
    const [canLoadMore, setCanLoadMore] = useState(true);

    useEffect(() => {

         // Add event listener for window resize
         window.addEventListener('resize', () => handleWindowSizeChange());

         // Cleanup the event listener when the component unmounts
         return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {
        setGotProjects(false);
        fetchUserProjects();

    }, [auth.modalUser]);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        // Fetch unread counts for notifications and chats
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [notification.notifications, auth.user]);

    useEffect(() => {

        // Update the badge value to total (Notifications + unread Chats)
        setBadgeValue(notification.num_unread + chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])

    useEffect(() => {

        renderProjectList();

    }, [project.projects, canLoadMore]);

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Initialize Mixpanel and track settings Help page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Settings Help Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    const fetchUserProjects = () => {

        if(auth.modalUser) {
            getUserProjects(auth.modalUser._id, campus_id);
            setGotProjects(true);
        }
    }

    const renderProjectList = async () => {
        setProjectList([]);

        if((project.projects === null && project.loading) || !gotProjects) {
            setProjectList([(
                <div className="no-rides">
                    <Spinner />
                </div>
            )])
        }
        else { 
            if(project.projects.length > 0) {
                project.projects.map(project_doc => {
                    setProjectList(projectList => [...projectList, (
                        <Project_Block key={project_doc._id} detail_Obj={project_doc} />
                    )])
                }) 
            } else {
                setProjectList(projectList => [...projectList, (
                    <div className="no-rides">
                        <h1>No Projects</h1>
                    </div>
                )])
                
            }
        } 

        setGotProjects(true);
    }

    // const loadMore = (lastPage_doc) => {
    //     console.log("LOADING MORE");
    //     console.log("last", lastPage_doc);

    //     // Temporarily disable to load more function to avoid duplicate fetch requests
    //     setCanLoadMore(false);

    //     // Show spinner
    //     set_ShowBottomSpinner(true);

    //     if(filter) {

    //         // Load posts with videos if on the profile's 'videos' tab
    //         if(filter === 'videos') {

    //             getUserVideos(modalUser._id, campus_id, lastPage_doc)

    //         } else if(filter === 'games') {

    //             getUserGames(modalUser._id, campus_id, lastPage_doc)

    //         }
            
            
    //     } else {
    //         getPostsByUserId(modalUser._id, campus_id, lastPage_doc)
    //     }
        
    //     // Re-enable the load more function after 1 second
    //     setTimeout(() => {
    //         setCanLoadMore(true);
    //     }, 1000);
    // }

    return (
        <div className="settings">

            {/* Header */}
            <div className="settings__header more_page_header">

                {/* Back Btn Icon & Badge Value */}
                <div onClick={goBack} className="profile__btnContainer">
                    <ArrowBackIcon />
                    {isMobile && badgeValue > 0 ? (
                        <span className="feed-header-badge defaultBadge">
                            {badgeValue}
                        </span>
                    ) : null}
                </div>

                {/* Title */}
                <div onClick={handleSlideMenu} className="settings__headerText">
                    <h3>
                        Projects
                    </h3>
                </div>
            </div>

            {/* Main Content */}
            <div className="settings__body terms">
                <div className="project_box_container" style={{paddingTop:'20px'}}>
                    {projectList}
                </div>
            </div>
            <div className="no-rides" />
        </div>
    )
}

Projects_Page.propTypes = {
    getUserProjects: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    project: state.project
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    getUserProjects,
})(Projects_Page);


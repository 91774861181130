import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Blurhash } from 'react-blurhash';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import { format, parseISO, fromUnixTime, formatDistanceToNow } from 'date-fns';

// Firebase
import { db } from '../../config/firebase-config';
import { collection, getDocs, getDoc, doc, query, where } from 'firebase/firestore';

// Actions
import { addLike, deletePost } from '../../actions/postActions';
import { getUserById } from '../../actions/authActions';
import { toggleAuthModal } from '../../actions/navActions';
import { setAlert } from '../../actions/alertActions';
import { createChat } from '../../actions/chatActions';

// Components
import { Button } from '@material-ui/core';
import ImgElement from './ImgElement';

// Icons = material UI
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';

// Modal
import Modal from '../../components/modal/Modal';
import ModalContainer from '../../components/modal/ModalContainer';
import New_ImageBox from '../../components/common/New_ImageBox';

const Image_Block = ({ 
    auth,
    nav: {
        campus_name
    },
    detailPost, 
    addLike,
    getUserById,
    toggleAuthModal,
    setAlert,
    deletePost,
    createChat,
    history
}) => {

    const [postImgs, setPostImgs] = useState(null);
    const [gotPostImgs, setGotPostImgs] = useState(false);

    // Window Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // ------ Post Actions Logic --------

    // Tracks the like status of post
    const [liked, setLiked] = useState(false);
    // Flag for checking if like is properly updated
    const [checkLike, setCheckLike] = useState(false);

    // Toggle Dropdwon
    const [dropdown, setDropdown] = useState(false);


    // Tracks the status of copying
    const [copied, setCopied] = useState(false);

    // Modal toggles
    const [deleteModal, setDeleteModal] = useState(false);
    const [messageModal, setMessageModal] = useState(false);
    const [copiedModal, setCopiedModal] = useState(false);

    // ------ END: Post Actions Logic --------

    let imgInfo = [];

    useEffect(() => {

        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 480;
    const isTablet = windowWidth <= 1000;

    // ----- Post Actions Logic -------

    // Reset the copied status after a delay
    useEffect(() => {
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    }, [copied]);

    // Handle post like action
    const handleLike = (postId) => {
        if(auth.user) {
            addLike(campus_name, postId, auth.user, detailPost);

            // Toggles the like status
            setLiked(!liked);
        } else {

            // Retrieves user information by ID
            getUserById(detailPost.user._id);

            // Toggles the authentication modal for liking
            toggleAuthModal('like');
        }
    }

    // Closes the dropdown menu
    const toggleCopiedModal = () => {
        setDropdown(false)
        
        // Sets an alert message indicating that the content has been copied to the clipboard
        // setCopiedModal(!copiedModal);
        setAlert('Copied to clipboard', `${!auth.loading && auth.isAuthenticated ? 'okay' : 'success'}`);
        
    }

    // Toggle the delete post confirmation modal
    const toggleDeleteModal = () => {
        setDropdown(false)

        // Toggles the delete modal
        setDeleteModal(!deleteModal); 
    }

    // Deletes the post
    const handleDelete = () => {
        if(detailPost) {
            deletePost(detailPost._id);
        }

        // Closes the delete modal
        setDeleteModal(false);
    }

    // Checks if the user has already liked the post
    if(auth.user && !checkLike) {
        if(detailPost.likes.filter(like => like.user._id.toString() === auth.user_id).length > 0){
            setLiked(true);
        }
        
        // Marks that the check for likes has been performed
        setCheckLike(true);
    } 

    // ----- END: Post Actions Logic -------

    // ------ Message Logic ------

    const toggleMessageModal = () => {

        // Get user by ID if the message modal is not currently open
        if(!messageModal) getUserById(detailPost.user._id);
        
        setMessageModal(!messageModal);
    }

    const continueMessage = () => {
        if(auth.user) {

            // If the user is authenticated, create a new chat
            createNewChat();
        } else {

            // If the user is not authenticated, close the message modal and show the authentication modal
            setMessageModal(false);
            toggleAuthModal('message');
        }
    }

    const createNewChat = async () => {
        const chatsCollectionRef = collection(db, "chats");

        // Check if chat with 2 users already exists by chat ID

        console.log('CHECK USER CHAT HERE')
        
        // Query chats where the current user is the from_user and the modal user is the to_user
        const chatsQuery = query(chatsCollectionRef, where("from_user._id", "==", auth.user._id), where("to_user._id", "==", auth.modalUser._id));

        // Query chats where the modal user is the from_user and the current user is the to_user
        const otherChatsQuery = query(chatsCollectionRef, where("to_user._id", "==", auth.user._id), where("from_user._id", "==", auth.modalUser._id));

        // Query chats where the current user is the from_user and the modal user is the to_user
        const chatsSnapshot = await getDocs(chatsQuery);

        // Map the document snapshots to an array of chat objects, including the document ID
        const chats = chatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        // Query chats where the modal user is the from_user and the current user is the to_user
        const otherChatsSnapshot = await getDocs(otherChatsQuery);

        // Map the document snapshots to an array of chat objects, including the document ID
        const otherChats = otherChatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('CHECKING CHATS DATA');
        console.log(chats);
        
        console.log('CHECKING OTHER CHATS DATA');
        console.log(otherChats);

        console.log('CHECK USER CHAT HERE PART 2')

        // If array length > 0 then there is already existing chat & redirect instead
      
        if(chats.length > 0) {
            history.push(`/messages/${chats[0]._id}`);
        } else if (otherChats.length > 0) {
            history.push(`/messages/${otherChats[0]._id}`);
        } else {

            // Create a new chat if no existing chat is found
            createChat(auth.modalUser, auth.user, history);
        }

        setMessageModal(!messageModal);
        
        console.log('SHOULD BE DONE CREATING');
    }

    // ------ End: Message Logic ------

    // ------ Get post Images ----------

    // Function to get post images
    async function getPostImgs() {
        const docRef = doc(db, 'posts', detailPost._id)
        const colRef = collection(docRef, "img_gallery")
        // console.log('FETCHING IMGS')
        try {
            // console.log('GALLERY DATA')
            const galleryData = await getDocs(colRef);
            const galleryList = galleryData.docs.map((doc) => ({...doc.data(), id: doc.id}));
            // console.log(galleryList);
            setPostImgs(galleryList)
            setGotPostImgs(true);
        } catch (err) {
            console.log('ERROR:');
            console.log(err);
        }
    }

    // Check if post images are not fetched yet and detailPost exists
    if(!gotPostImgs && detailPost) {

        // Fetch post images
        getPostImgs()

        // Don't fetch imgs again after this
        setGotPostImgs(true);
    }

    

    if(postImgs && postImgs.length > 0) {
        postImgs.map(image => {
            imgInfo.push({
                img_path: image.img_path,
                width: image.img_width,
                height: image.img_height
            })
        })
    } else {
        imgInfo = null;
    }

    let imageContent = (<></>);

    if(imgInfo) {
        imageContent = (
            <div onClick={isTablet ? () => window.location = `/post/${detailPost._id}` : null} key={detailPost._id} className='product_media_image_container'>
                <ImgElement img_obj={imgInfo[0]} />

                {!isTablet ? (
                    /* Overlay */
                    <div className="media_image_overlay">
                        <div className="media_overlay_container top" onClick={() => window.location = `/post/${detailPost._id}`}>
                            <p>{formatDistanceToNow(detailPost.date)} ago</p>
                        </div>
                        <div className="media_overlay_container bottom">
                            <div className="media_overlay_section longer">

                                {/* -- Render the "Send Message" button based on user authentication */}

                                {auth.user && detailPost.user._id !== auth.user._id && detailPost ? (
                                    <div onClick={toggleMessageModal} className="media_overlay_section_icon_container">
                                        <span>Message</span>
                                    </div>
                                ) : null} 
                                
                                {!auth.user ? (
                                    <div onClick={toggleMessageModal} className="media_overlay_section_icon_container">
                                        <span>Message</span>
                                    </div>
                                ) : null} 

                                {/* -- END: Render the "Send Message" button based on user authentication */}

                            </div>
                            <div onClick={() => handleLike(detailPost._id)} className="media_overlay_section">
                                <div className={`media_overlay_section_icon_container ${liked ? "active" : ""}`}>
                                    <div className="media_overlay_section_icon">
                                        <FavoriteIcon />
                                    </div> 
                                </div>
                            </div>
                            <div className="media_overlay_section">
                                <div onClick={() => setDropdown(!dropdown)} className="media_overlay_section_icon_container">
                                    <MoreHorizIcon />
                                </div>

                                {/* Dropdown Menu */}
                                <div className={dropdown ? "edit-dropdown active" : "edit-dropdown"}>
                                    <div className="menu">

                                        {/* Copy Link Menu Item */}
                                        <CopyToClipboard 
                                            text={`${process.env.REACT_APP_DOMAIN}post/${detailPost._id}?redirect=true`}
                                            onCopy={toggleCopiedModal}
                                        >
                                            <div className="menu-item">
                                                {!copied ? (
                                                    <Fragment>
                                                        <LinkIcon />
                                                        Copy link
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <CheckIcon />
                                                        Copied!
                                                    </Fragment>
                                                )}
                                            </div>
                                        </CopyToClipboard>

                                        {/* Delete Menu Item (shown only for authenticated user who created the post) */}
                                        {auth.user && detailPost.user._id === auth.user_id ? (
                                            <div onClick={toggleDeleteModal} className="menu-item danger">
                                                <DeleteIcon />
                                                Delete
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                {/* Hidden Overlay for dropdown */}
                                <div
                                    className={`hidden-overlay ${dropdown ? "show" : ""}`}
                                    onClick={() => setDropdown(!dropdown)}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    /* Overlay */
                    <div className="media_image_overlay">
                    </div>
                )}
            </div>
        )
    }

    // ------ End: Get post Images ----------
    

    return (
        <Fragment>

            {imageContent}

            {/* Delete Post Modal */}
            <ModalContainer show={deleteModal} onClose={toggleDeleteModal}>
                    <Modal>

                        {/* Modal Title */}
                        <div>
                            <h2>Delete Post?</h2>
                        </div>

                        {/* Modal Description */}
                        <p>This can’t be undone and it will be removed from your profile, the main feed, and from any search results.</p>

                        {/* Modal Actions */}
                        <Button 
                            onClick={handleDelete}
                            variant="outlined" 
                            className="sidebar__tweet danger"
                            fullWidth
                        >
                            Delete
                        </Button>
                        <Button 
                            onClick={toggleDeleteModal}
                            variant="outlined" 
                            className="sidebar__tweet ghost"
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Modal>
                </ModalContainer>

                {/* Create Message confirmation Modal */}  
                <ModalContainer show={messageModal} onClose={toggleMessageModal}>
                    <Modal>

                        {/* Modal Title */}
                        <div>
                            <h2>Start Conversation?</h2>
                        </div>

                        {/* Modal Description */}
                        <p>Start a private conversation with <b style={{color: '#333'}}>{detailPost.username ? detailPost.username : 'this person'}</b>.</p>
                        
                        {/* Modal Actions */}
                        <Button 
                            onClick={continueMessage}
                            variant="outlined" 
                            className="sidebar__tweet black"
                            fullWidth
                        >
                            Start Conversation
                        </Button>
                        <Button 
                            onClick={toggleMessageModal}
                            variant="outlined" 
                            className="sidebar__tweet ghost blackText"
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Modal>
                </ModalContainer>
        </Fragment>
    )
}


Image_Block.propTypes = {
    // Prop type validation for post data
    post: PropTypes.object.isRequired,
    // Prop type validation for navigation
    nav: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    addLike: PropTypes.func.isRequired,
    getUserById: PropTypes.func.isRequired,
    toggleAuthModal: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    deletePost: PropTypes.func.isRequired,
    createChat: PropTypes.func.isRequired
}

// Map these states from Redux store to the component props
const mapStateToProps = state => ({
    post: state.post,
    nav: state.nav,
    auth: state.auth
})

// Connect the Image_Block component to the Redux store and export it
export default connect(mapStateToProps, { 
    addLike, 
    getUserById, 
    toggleAuthModal, 
    setAlert,
    deletePost,
    createChat
})(Image_Block);


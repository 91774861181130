import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions
import { setPage, toggleLocationModal, togglePostModal } from '../../actions/navActions';

// Components - imported
import { Avatar, Button } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';
import GoogleBtn from '../common/GoogleBtn';
import SidebarProfileWidget from './SidebarProfileWidget';

// Icons -imported
import TwitterIcon from '@material-ui/icons/Twitter';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import StorefrontIcon from '@material-ui/icons/Storefront';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MailIcon from '@material-ui/icons/Mail';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PersonIcon from '@material-ui/icons/Person';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import FastfoodOutlinedIcon from '@material-ui/icons/FastfoodOutlined';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import SportsEsportsOutlinedIcon from '@material-ui/icons/SportsEsportsOutlined';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';

import SidebarOption from './SidebarOption';

import tunde_logo from '../../utils/imgs/tunde_logo.png';

const Sidebar = ({
    setPage,
    togglePostModal,
    toggleLocationModal,
    auth: { 
        user, 
        modalUser,
        isAuthenticated, 
        loading 
    }, 
    nav: { 
        page,
        isMarketplace,
        // - Campus Logic -
        campus_name,
        campus_loading
        // - END: Campus Logic -
    },
    isMobile,
    isTablet
}) => {

    // State to determine highlighted sidebar option
    const [navHighlight, setNavHighlight] = useState(null);

    // Toggle Dropdwon
    const [profileDropdown, setProfileDropdown] = useState(false);

    // --- Set the navigation highlight based on the current page
    useEffect(() => {
        if(page === 'home') {
            setNavHighlight('home');
        }
        if(page === 'search') {
            setNavHighlight('search');
        }
        if(page === 'notifications') {
            setNavHighlight('notifications');
        }

        if(page === 'messages') {
            setNavHighlight('messages');
        }

        if(page === 'saved') {
            setNavHighlight('saved');
        }

        if(page === 'profile') {
            setNavHighlight('profile');
        }

        if(page === 'settings') {
            setNavHighlight('settings');
        }

        // - Marketplace ---
        
        if(page === 'marketplace' || page === 'grocery landing' || page === 'product detail' || page === 'cart' || page === 'checkout' || page === 'collection') {
            setNavHighlight('grocery');
        }

    }, [page]);

    // Handle navigation to a specific page
    const todo = (page) => {
        setNavHighlight(page);
        setPage(page);
    }

    // Get User info
    let username = null;

    // Check if the user is logged in and set the username
    if(user) {
        username = user.username;
    }

    let isMyProfile = false;

    // Check if the current highlighted navigation is 'profile'
    if(navHighlight === "profile") {
        if(user && modalUser && user._id === 'pjaWd3NhsqNtC5fw2PRROQdf28F2') {
            isMyProfile = true;
        } else {
            isMyProfile = false;
        }
    }

    let user_name;

    if(!loading && isAuthenticated) {
        user_name = `${user.first_name} ${user.last_name && user.last_name}`
    }

    // --- ImageKit (img optimization service) ---
    
    let optimized_profile_img_path;

    if(user && user.img) {
   
        // Replace the firebase URL with ImageKit.io URL-endpoint
        optimized_profile_img_path = user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    }

    // --- END: ImageKit (img optimization service) ---

    return (
        <div className="sidebar" style={{display:'flex', position:'relative', flexDirection: 'column'}}>
            {/* <TwitterIcon className="sidebar__twitterIcon" /> */}
            <div onClick={() => window.location = '/'} style={{cursor: 'pointer'}}>
                 <img className={`sidebar__twitterIcon small_logo`} src={tunde_logo} alt="logo" />
            </div>

            {/* --- Sidebar options */}

            <SidebarOption active={navHighlight === "home"} Icon={navHighlight === "home" ? HomeIcon : HomeOutlinedIcon} text="Home" link="/" />
            <SidebarOption active={navHighlight === "search"} Icon={SearchOutlinedIcon} text="Search" link="/search" />
            {/* <SidebarOption active={navHighlight === "settings"} Icon={SportsEsportsOutlinedIcon} text={"Games"} link="/settings" />
            <SidebarOption active={navHighlight === "settings"} Icon={AccountTreeOutlinedIcon} text={"Projects"} link="/settings" /> */}

            {/* If the user is authenticated, show "More" as the text, otherwise show "Settings" */}
            {/* {!isAuthenticated && (
                <SidebarOption active={navHighlight === "settings"} Icon={!loading && isAuthenticated ? MoreHorizIcon : SettingsOutlinedIcon} text={!loading && isAuthenticated ? "More" : "Settings"} link="/settings" />
            )} */}

            {/* {!isAuthenticated && ( <SidebarOption active={navHighlight === "howitworks"} Icon={DeviceUnknownOutlinedIcon} text="How It Works" link="/howitworks" /> )} */}

            {/* Conditional rendering for create post button */}

            {!loading && isAuthenticated && (
                <Fragment>
                    {isAuthenticated && isMyProfile && (
                        <Button onClick={togglePostModal} variant="outlined" className="sidebar__tweet compose" fullWidth>
                            {!isTablet ? 'Create Post' : <AddIcon />}
                        </Button>
                    )}

                    <div style={{flex: 0.8, alignSelf: 'stretch'}}>
                                
                    </div>

                    {/* Quick Profile Button */}
                    <SidebarProfileWidget sidebarMobile={false} />
                </Fragment>
            )}

        </div>
    )
}

Sidebar.propTypes = {
    auth: PropTypes.object.isRequired, // Object prop representing the authentication data for the user
    nav: PropTypes.object.isRequired, // Prop: Object representing navigation state
    setPage: PropTypes.func.isRequired, // Function prop to set the current page
    togglePostModal: PropTypes.func.isRequired, // Function prop to toggle the post modal
    toggleLocationModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav
})

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setPage, 
    togglePostModal,
    toggleLocationModal
})(Sidebar);

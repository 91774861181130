import React from 'react'
import PropTypes from 'prop-types'

const Readme = props => {
    return (
        <div className='readme_body'>
            <main>
        <h1 id="crop-recommendation-system">Crop Recommendation System</h1>
<p>This project is a machine learning-powered web application that predicts the most suitable crop to plant based on soil and weather data. The app is built using Flask and a Random Forest Classifier.  </p>
<p><img src="https://github.com/tunde262/crop_prediction_ml_model_flask/blob/main/static/thumbnail.png?raw=true" alt="thumbnail" /></p>
<h2 id="project-goal">Project Goal</h2>
<p>The goal of the project was to implement a machine learning solution to solve a real-world problem in agriculture.</p>
<h2 id="features">Features</h2>
<ul>
<li>Predicts crop recommendations using a trained Random Forest Classifier.  </li>
<li>Accepts key agricultural inputs like Nitrogen, Phosphorus, Potassium, Temperature, Humidity, pH, and Rainfall.  </li>
<li>Displays the predicted crop on the web interface.  </li>
<li>Provides an example of integrating machine learning models with web applications.  </li>
</ul>
<h2 id="technologies">Technologies</h2>
<ul>
<li><strong>Backend:</strong> Flask, Python  </li>
<li><strong>Machine Learning:</strong> Random Forest Classifier, Scikit-learn  </li>
<li><strong>Frontend:</strong> HTML/CSS  </li>
<li><strong>Data Handling:</strong> Pandas, NumPy  </li>
<li><strong>Model Deployment:</strong> Pickle  </li>
</ul>
<h2 id="dataset">Dataset</h2>
<p>The model is trained on the <strong>Crop_recommendation.csv</strong> dataset, which contains data on:  </p>
<ul>
<li>Soil nutrients (Nitrogen, Phosphorus, Potassium)  </li>
<li>Weather factors (Temperature, Humidity, Rainfall)  </li>
<li>Soil pH levels  </li>
<li>Corresponding crop labels  </li>
</ul>
<h2 id="installation">Installation</h2>
<p>Before running the application, install the required Python libraries:  </p>
<pre><code class="hljs bash language-bash">pip install flask numpy pandas scikit-learn</code></pre>
<h2 id="how-to-run">How To Run</h2>
<ol>
<li><p>Clone this repository.</p></li>
<li><p>Ensure the dataset file <code>Crop_recommendation.csv</code> is in the project directory.</p></li>
<li><p>Train the machine learning model by running <code>model.py</code>. This will generate the <code>model.pkl</code> file.</p></li>
</ol>
<pre><code class="hljs bash language-bash">   python model.py</code></pre>
  <br/>
<ol start="4">
<li>Start the Flask app by running <code>app.py</code>:</li>
<pre><code class="hljs bash language-bash"> 
python app.py
</code></pre>
</ol>
<br/>
<ol start="5">
<li><p>Open a web browser and navigate to <b><a href="http://127.0.0.1:5000/**">http://127.0.0.1:5000/</a></b>.</p></li>
<li><p>Enter your sample inputs and click "Predict" to see the recommended crop.</p></li>
</ol>
<h2 id="acknowledgments">Acknowledgments</h2>
<p>This project was inspired by <a href="https://youtu.be/Rynv-ueHzwU?si=oQXDLpS_r36D0sjf">AlgoChat</a> for the original concept and dataset.</p>
            </main>
        </div>
  )
}

Readme.propTypes = {}

export default Readme;
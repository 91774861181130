import React, { Fragment, useEffect, useState } from 'react';

import mixpanel from 'mixpanel-browser';

// components - material UI
import { Button } from '@material-ui/core';

// icons - material UI
import InstagramIcon from '@material-ui/icons/Instagram';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Projects_Page = ({ 
    handleSlideMenu, 
    auth,
    chat,
    notification,
    getUnreadChats,
    getUnreadCount,
    history,
    campus_name,
    goBack
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    useEffect(() => {

         // Add event listener for window resize
         window.addEventListener('resize', () => handleWindowSizeChange());

         // Cleanup the event listener when the component unmounts
         return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        // Fetch unread counts for notifications and chats
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [notification.notifications, auth.user]);

    useEffect(() => {

        // Update the badge value to total (Notifications + unread Chats)
        setBadgeValue(notification.num_unread + chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Initialize Mixpanel and track settings Help page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Settings Help Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <div className="settings">

            {/* Header */}
            <div className="settings__header more_page_header">
                {/* Back Btn Icon & Badge Value */}
                <div onClick={goBack} className="profile__btnContainer">
                    <ArrowBackIcon />
                    {isMobile && badgeValue > 0 ? (
                        <span className="feed-header-badge defaultBadge">
                            {badgeValue}
                        </span>
                    ) : null}
                </div>

                {/* Title */}
                <div onClick={handleSlideMenu} className="settings__headerText">
                    <h3>
                        FAQ
                    </h3>
                </div>
            </div>

            {/* Main Content */}
            {/* Main Content */}
            <div className="settings__body terms">
                <div className="terms-of-service" style={{paddingTop:'20px'}}> 
                    <p style={{textAlign: 'center', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', lineHeight:1.5}}>
                        I often get asked these questions.
                    </p>
                    <div>
                        {/* Question #1 */}
                        <div style={{fontWeight: 700, fontStyle: 'italic', textAlign: 'center', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', lineHeight:1.5}}>
                            How old are you?
                        </div>
                        {/* Answer #1 */}
                        <div style={{textAlign: 'center', fontFamily: 'Calibri, Candara, Segoe, segoe ui, Optima'}}>
                            23
                        </div>
                        <br />

                        {/* Question #2 */}
                        <div style={{fontWeight: 700, fontStyle: 'italic', textAlign: 'center', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', lineHeight:1.5}}>
                            Where are you from?
                        </div>
                        {/* Answer #2 */}
                        <div style={{textAlign: 'center', fontFamily: 'Calibri, Candara, Segoe, segoe ui, Optima'}}>
                            Dallas, Texas
                        </div>
                        <br />

                        {/* Question #3 */}
                        <div style={{fontWeight: 700, fontStyle: 'italic', textAlign: 'center', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', lineHeight:1.5}}>
                            Where are you from? No, like where are you actually from?
                        </div>
                        {/* Answer #3 */}
                        <div style={{textAlign: 'center', fontFamily: 'Calibri, Candara, Segoe, segoe ui, Optima'}}>
                            Both of my parents are originally from Nigeria.
                        </div>
                        <br />

                        {/* Question #4 */}
                        <div style={{fontWeight: 700, fontStyle: 'italic', textAlign: 'center', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', lineHeight:1.5}}>
                            When did you start learning to code?
                        </div>
                        {/* Answer #4 */}
                        <div style={{textAlign: 'center', fontFamily: 'Calibri, Candara, Segoe, segoe ui, Optima'}}>
                            Ten years ago - in 2014. My goal was to build an obstacle avoiding robot.
                        </div>
                        <br />

                        {/* Question #5 */}
                        <div style={{fontWeight: 700, fontStyle: 'italic', textAlign: 'center', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', lineHeight:1.5}}>
                            How did you start learning to code?
                        </div>
                        {/* Answer #5 */}
                        <div style={{textAlign: 'center', fontFamily: 'Calibri, Candara, Segoe, segoe ui, Optima'}}>
                            Youtube.
                        </div>
                        <br />

                        {/* Question #6 */}
                        <div style={{fontWeight: 700, fontStyle: 'italic', textAlign: 'center', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', lineHeight:1.5}}>
                            If I ever can't find you where should I look?
                        </div>
                        {/* Answer #6 */}
                        <div style={{textAlign: 'center', fontFamily: 'Calibri, Candara, Segoe, segoe ui, Optima'}}>
                            Tokyo, Japan - I've always wanted to travel here
                        </div>
                        <br />

                        {/* Question #7 */}
                        <div style={{fontWeight: 700, fontStyle: 'italic', textAlign: 'center', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', lineHeight:1.5}}>
                            Where do you see yourself in 10 years?
                        </div>
                        {/* Answer #7 */}
                        <div style={{textAlign: 'center', fontFamily: 'Calibri, Candara, Segoe, segoe ui, Optima'}}>
                            CEO of a big tech company, inventing new things to help millions of people.
                        </div>
                        <br />

                        {/* Question #8 */}
                        <div style={{fontWeight: 700, fontStyle: 'italic', textAlign: 'center', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', lineHeight:1.5}}>
                            You create so many things. How do you find the time?
                        </div>
                        {/* Answer #8 */}
                        <div style={{textAlign: 'center', fontFamily: 'Calibri, Candara, Segoe, segoe ui, Optima'}}>
                            I try to focus on just one thing at a time TBH. I guess I've just been doing this for awhile.
                        </div>
                        <br />

                    </div>
                </div>
            </div>
            <div className="no-rides" />
        </div>
    )
}

export default Projects_Page;

import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Firebase
import { db } from '../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { getPostById } from '../actions/postActions';
import { setPage, add_To_Recent_History, toggleAuthModal } from '../actions/navActions';
import { createChat } from '../actions/chatActions';

// Layout Wrapper
import Layout from '../components/layout/Layout';

import UserTable from '../components/Tables/UserTable/UserTable';

// Components
import { Button } from '@material-ui/core';

// Modal
import Modal from '../components/modal/Modal';
import ModalContainer from '../components/modal/ModalContainer';

const LikedBy = ({ 
    setPage, 
    add_To_Recent_History,
    toggleAuthModal,
    getPostById, 
    createChat,
    auth: { 
        user,
        modalUser, 
    }, 
    match, 
    history 
}) => {

    // State variable to track if Mixpanel event has been sent
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // State variable to toggle message modal
    const [messageModal, setMessageModal] = useState(false);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    useEffect(() => {

        // Set the current page to 'likes'
        setPage('likes');
        add_To_Recent_History(url.pathname);  // current url

        // Fetch the post by ID
        getPostById(match.params.id);

    }, [ match.params.id ]);

    // --- Message Logic

    // Toggles the visibility of the message modal
    const toggleMessageModal = () => {
        
        setMessageModal(!messageModal);
    }

    // Handles Starting a Conversation
    const continueMessage = () => {

        // If user is authenticated, create a new chat
        if(user) {
            createNewChat();
        } else {

            // Toggle message modal and show authentication modal with 'message' alert
            toggleMessageModal();
            toggleAuthModal('message');
        }
    }

    // Create a new chat between users
    const createNewChat = async () => {

        // Query existing chats between users
        const chatsCollectionRef = collection(db, "chats");

        // Check if chat with 2 users already exists by chat ID

        console.log('CHECK USER CHAT HERE')
        
        const chatsQuery = query(chatsCollectionRef, where("from_user._id", "==", user._id), where("to_user._id", "==", modalUser._id));
        const otherChatsQuery = query(chatsCollectionRef, where("to_user._id", "==", user._id), where("from_user._id", "==", modalUser._id));

        const chatsSnapshot = await getDocs(chatsQuery);
        const chats = chatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        const otherChatsSnapshot = await getDocs(otherChatsQuery);
        const otherChats = otherChatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        // Check if chats were retrieved in console (for testing)
        console.log('CHECKING CHATS DATA');
        console.log(chats);
        
        console.log('CHECKING OTHER CHATS DATA');
        console.log(otherChats);

        console.log('CHECK USER CHAT HERE PART 2')

        // If array length > 0 then there is already existing chat & redirect instead
      
        if(chats.length > 0) {
            history.push(`/messages/${chats[0]._id}`);
        } else if (otherChats.length > 0) {
            history.push(`/messages/${otherChats[0]._id}`);
        } else {
            createChat(modalUser, user, history);
        }

        setMessageModal(!messageModal);
        
        console.log('SHOULD BE DONE CREATING');
    }

    // End

    // Handles Mixpanel tracking
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

        // Track "Liked By Page View" event
        mixpanel.track("Liked By Page View");
    }

    // Checks if the environment is production and Mixpanel tracking hasn't been sent
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {

        // Call Mixpanel tracking function
        handleMixpanel();

        // Set Mixpanel event as sent
        setSentMixpanel(true);
    }

    return (
        <Fragment>
            <Layout page="liked by">
                <div className="modal-table-list-container modular">

                    {/* UserTable component */}
                    <UserTable toggleMessageModal={toggleMessageModal} />
                </div>
            </Layout>

            {/* Start a Conversation modal */}
            <ModalContainer show={messageModal} onClose={toggleMessageModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Start Conversation?</h2>
                    </div>

                    {/* Modal Text */}
                    <p>Start a private conversation with <b style={{color: '#333'}}>{modalUser ? `${modalUser.first_name} ${modalUser.last_name.charAt(0).toUpperCase()}` : 'this person'}</b>.</p>
                    
                    {/* Modal Actions */}
                    <Button 
                        onClick={continueMessage}
                        variant="outlined" 
                        className="sidebar__tweet black"
                        fullWidth
                    >
                        Start Conversation
                    </Button>
                    <Button 
                        onClick={toggleMessageModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Modal>
            </ModalContainer>
        </Fragment>
    )
}

LikedBy.propTypes = {
    auth: PropTypes.object.isRequired, // Object prop representing the authentication state of the user
    setPage: PropTypes.func.isRequired, // Function prop to set the current page in the navigation
    add_To_Recent_History: PropTypes.func.isRequired,
    toggleAuthModal: PropTypes.func.isRequired,
    getPostById: PropTypes.func.isRequired,
    createChat: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    setPage, 
    add_To_Recent_History,
    toggleAuthModal, 
    getPostById, 
    createChat 
})(LikedBy);

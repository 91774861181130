import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { getPostById } from '../../actions/postActions';
import { getGameById } from '../../actions/gameActions';
import { setPage, add_To_Recent_History, goBack } from '../../actions/navActions';

// Layout Wrapper
import Layout from '../../components/layout/Layout';

// components
import Spinner from '../../components/common/Spinner';
import TweetBox from '../../components/TweetBox/TweetBox';
import ModalPost from '../../components/Post/ModalPost';
import { Button } from '@material-ui/core';

// Icons - material UI
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Modal
import ModalContainer from '../../components/modal/ModalContainer';
import Modal from '../../components/modal/Modal';

const CreateComment = ({ 
    post: { 
        post 
    },
    game: {
        game
    },
    nav,
    setPage,
    add_To_Recent_History,
    goBack,
    getPostById,
    getGameById,
    match,
    history,
}) => {
    
    // State variable for tracking Mixpanel event
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Window width state
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    // Modal toggles
    const [discardModal, setDiscardModal] = useState(false);

    // Uploading state
    const [uploading, setUploading] = useState(false);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const type_of_comment = url.searchParams.get("type");

    useEffect(() => {
        
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);


    // When the url changes
    useEffect(() => {

        // Set current page to 'comment'
        setPage('comment');
        add_To_Recent_History(url.pathname);  // current url

        // Get the document from the ID in the URL
        if(type_of_comment === 'post') {
            getPostById(match.params.id);
        } else if(type_of_comment === 'game') {
            getGameById(match.params.id);
        } else {
            getPostById(match.params.id);
        }
        
        
    }, [match.params.id, type_of_comment])

    // Function to handle window size change
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Function to toggle discard modal
    const toggleDiscardModal = () => {
        setDiscardModal(!discardModal);
    }

    // Logic for handling discard action
    const handleDiscard = () => {
        setDiscardModal(false);
        
        // history.goBack()
        // window.location = `/post/${post._id}`
        goBack();
    }

    // Check if the window size is mobile or tablet
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Redirect to the post page if not on a tablet and post is available
    if(!isMobile) {

        if(post) {
            history.push(`/post/${post._id}`);
        }

        if(game) {
            history.push(`/game/${game._id}`);
        }
    }

    // Mixpanel tracking logic
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Mobile Comment Page View");
    }

    // Trigger Mixpanel tracking on first render
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    // Simulate uploading state for demonstration purposes
    if(uploading) {
        setTimeout(() => {
            setUploading(false);
        }, 2500 );
    }

    return (
        <Fragment>
            <div className="createPost__page">

                { /* Render spinner if uploading is true, otherwise render comment creation */}
                {uploading ? <Spinner /> : (
                    <Fragment>

                        {/* Comment creation coomponents */}
                        <div onClick={toggleDiscardModal} className="closeBtn__container start">
                            
                            {/* Button to go Back */}
                            <button  className="secondaryBtn close">
                                <ArrowBackIcon /> 
                                <span>Back</span>
                            </button>
                            {/* <Button className="tweetBox__tweetButton sliding">Tweet</Button> */}
                        </div>

                        {/* TweetBox */}
                        <div style={{padding:'10px 10px 10px 20px'}}>
                            {post && <ModalPost post={post} />}
                            <TweetBox postId={post && post._id} gameId={game && game._id} modal sliding comment uploading={uploading} setUploading={setUploading} history={history} />
                        </div>
                    </Fragment>
                )}
            </div>

            {/* Discard Modal */}
            <ModalContainer show={discardModal} onClose={toggleDiscardModal}>
                <Modal>

                    {/* Title */}
                    <div>
                        <h2>Cancel Comment?</h2>
                    </div>

                    {/* Description */}
                    <p>Are you sure? Your draft will not be saved.</p>
                    
                    {/* Actions */}
                    <Button 
                        onClick={handleDiscard}
                        variant="outlined" 
                        className="sidebar__tweet black"
                        fullWidth
                    >
                        Discard
                    </Button>
                    <Button 
                        onClick={toggleDiscardModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Keep Writing
                    </Button>
                </Modal>
            </ModalContainer>
        </Fragment>
    )
}

CreateComment.propTypes = {
    getPostById: PropTypes.func.isRequired,
    getGameById: PropTypes.func.isRequired,
    post: PropTypes.object.isRequired,
    game: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    post: state.post,
    game: state.game,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setPage, 
    add_To_Recent_History,
    goBack,
    getPostById,
    getGameById
})(CreateComment);

import React from 'react'
import PropTypes from 'prop-types'

const Readme = props => {
    return (
        <div className='readme_body'>
            <main>
        <h1 id="brick-breaker-game">Brick Breaker Game</h1>
<p>A simple Brick Breaker game implemented using <strong>Python</strong> and <strong>Pygame</strong>. The game includes a paddle, a bouncing ball, and destructible bricks. Players can control the paddle to keep the ball in play and break all the bricks to win. </p>
<p>The program also includes optional web hosting support using <strong>asyncio</strong> &amp; <strong>pygbag</strong>.</p>
<p><img src="https://github.com/tunde262/brick_breaker_game_python/blob/main/assets/thumbnail.png?raw=true" alt="thumbnail" /></p>
<h2 id="features">Features</h2>
<ul>
<li><strong>Dynamic Gameplay:</strong> Paddle and ball mechanics for an engaging experience.</li>
<li><strong>Multiple Brick Layers:</strong> Bricks have varying strength levels based on their row.</li>
<li><strong>Victory & Game Over Conditions:</strong> Win by destroying all bricks or lose if the ball falls below the paddle.</li>
<li><strong>Optional Web Hosting</strong> on the web using asyncio compatibility with the Pygbag library..</li>
</ul>
<hr />
<h2 id="requirements">Requirements</h2>
<ul>
<li>Python 3.8+, recommended for the following reasons:<ol>
<li><strong>Enhanced <code>asyncio</code> Support</strong>: The project uses <code>asyncio.run()</code> for the optional web-hosting feature, which simplifies asynchronous execution.</li>
<li><strong>Compatibility with Pygbag</strong>: Pygbag, used for web hosting, requires Python 3.8+.</li></ol></li>
<li>Pygame (install via <code>pip install pygame</code>)</li>
<li>(Optional) Pygbag for web hosting</li>
</ul>
<h2 id="installation">Installation</h2>
<ol>
<li><strong>Clone the Repository</strong>:</li>
</ol>
<pre><code class="hljs bash language-bash">   git <span class="hljs-built_in">clone</span> https://github.com/tunde262/brick_breaker_game_python.git
   <span class="hljs-built_in">cd</span> brick_breaker_game_python</code></pre>
<ol start="3">
<li><strong>Install Dependencies</strong>: Ensure you have Python 3.8+ installed. Then, install the required libraries:</li>
</ol>
<pre><code class="hljs bash language-bash">   pip install pygame</code></pre>
<ol start="4">
<li><strong>Run the Game</strong>:</li>
</ol>
<pre><code class="hljs bash language-bash">   python main.py</code></pre>
<hr />
<h2 id="web-hosting-optional">Web Hosting (Optional)</h2>
<p>This project supports optional web hosting using <code>asyncio</code> and the <strong>Pygbag</strong> library.</p>
<ol>
<li><strong>Install the Pygbag library</strong>:</li>
</ol>
<pre><code class="hljs bash language-bash">   pip install pygbag</code></pre>
<ol start="2">
<li><strong>Compile and host the game</strong>:</li>
</ol>
<pre><code class="hljs bash language-bash">   pygbag main.py</code></pre>
<ol start="3">
<li>Follow the output instructions to serve the game in a browser.</li>
</ol>
<h2 id="how-to-play">How to Play</h2>
<ul>
<li>Use the arrow keys to move the paddle.</li>
<li>Prevent the ball from falling below the paddle.</li>
<li>Destroy all the bricks to win.</li>
<li>The screen updates in real time, maintaining a 60 FPS frame rate for smooth play.</li>
</ul>
<h2 id="game-controls">Game Controls</h2>
<ul>
<li><code>Click anywhere</code> : Start the game</li>
<li><code>→</code> - Move Right</li>
<li><code>←</code> - Move Left</li>
</ul>
<hr />
<h2 id="future-enhancements">Future Enhancements</h2>
<ul>
<li>Add power-ups (e.g., wider paddle, faster ball).</li>
<li>Implement scoring and a high-score tracker.</li>
<li>Introduce sound effects and background music.</li>
<li>Mobile-friendly version with touch controls.</li>
<li>Add on-screen instructions.</li>
</ul>
<hr />
<h3 id="author">Author</h3>
<p><em>Tunde Adepitan</em>  <br />
GitHub: <a href="https://github.com/tunde262">tunde262</a>  <br />
Feel free to connect and give me feedback!</p>
            </main>
        </div>
  )
}

Readme.propTypes = {}

export default Readme;
import React from 'react'
import PropTypes from 'prop-types'

const Readme = props => {
    return (
        <div className='readme_body'>
            <main>
                <h1 id="campus-buy-sell">Miitoken</h1>
                <h2 id="about-the-project">About the Project</h2>
                <p>Local groceries delivered hyper fast</p>
               
            </main>
        </div>
  )
}

Readme.propTypes = {}

export default Readme;
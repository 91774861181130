import axios from 'axios';
import { setAlert } from './alertActions';
import { createNotification } from './notificationActions';
import { sendEmail } from './emailActions';

import { db, auth, storage } from '../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, serverTimestamp, orderBy } from 'firebase/firestore';
import { uploadBytes, ref, getDownloadURL, uploadBytesResumable, deleteObject } from 'firebase/storage';

// DraftJS
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import {  
    GET_CHAT,
    GET_CHATS,
    CREATE_CHAT,
    ADD_MESSAGE,
    GET_MESSAGES,
    SET_MESSAGES,
    SET_MESSAGE,
    UPDATE_LAST_MESSAGE,
    SET_UNREAD_CHATS,
    CLEAR_MESSAGES,
    CHAT_ERROR,
    CLEAR_CHAT,
    CLEAR_CHATS,
    CHATS_LOADING,
    CHAT_LOADING,
    UPDATE_EDITOR_TEXT,
    CLEAR_EDITOR_TEXT,
    UPDATE_EDITOR_IMG_FILE,
    CLEAR_EDITOR_IMG_FILE,
    UPDATE_IMG_URL,
    REMOVE_IMG_URL
} from './types';

// Reference to the "chats" collection in Firestore
const chatsCollectionRef = collection(db, "chats");

// Reference to the "messages" collection in Firestore
const messagesCollectionRef = collection(db, "messages");

const removeHtmlTags = (str) => {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
}

// Get Current user chats 
export const getUserChats = () => async dispatch => {
    try {
        let chats = [];
        let otherChats = [];

        console.log('QUERYING USER CHATS');

        // Query the "chats" collection to retrieve chats where the current user is included in the "current_users" array, ordered by createdAt in descending order
        const chatsQuery = query(chatsCollectionRef, where("current_users", "array-contains", auth.currentUser.uid), orderBy('lastModified', 'desc'));

        // Query to retrieve other chats where the current user is the recipient (to_user)
        // const otherChatsQuery = query(chatsCollectionRef, where("to_user._id", "==", auth.currentUser.uid));

        console.log('QUERYED USER CHATS');

        // Retrieve the chats that match the chatsQuery
        const chatsSnapshot = await getDocs(chatsQuery);

        // Map the retrieved chats to an array of chat objects with additional "_id" field
        chats = chatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        // Retrieve other chats if needed
        // const otherChatsSnapshot = await getDocs(otherChatsQuery);
        // otherChats = otherChatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        // Combine user chats and other chats into a single array
        let userChats = [...chats, /*...otherChats*/];

        console.log('USER CHATS DATA');

        // Dispatch an action to update the state with the user's chats
        dispatch({
            type: GET_CHATS,
            payload: userChats
        });
    } catch (err) {
        console.log('ERROR GETTING USER CHATS!!!')
        console.log(err)

        // Dispatch an action to handle the chat error, providing an error message and status
        dispatch({
            type: CHAT_ERROR,
            payload: { msg: 'Error getting user chats', status: 500 }
        });
        
    }
}


// Get single Chat by id
export const getChatById = id => async dispatch => {

    // Set the chat loading state
    dispatch(setChatLoading());
    try {

        // Create a reference to the chat document using the provided id
        const docRef = doc(db, 'chats', id)

        // Retrieve the chat document using the docRef
        const chatDoc = await getDoc(docRef);

        console.log('GOT CHAT BY ID');
  
        // Dispatch an action to update the state with the retrieved chat data
        dispatch({
            type: GET_CHAT,
            payload: {
                ...chatDoc.data(),
                _id: id
            }
        });
    } catch (err) {

        // Dispatch an action to handle the chat error, providing an error message and status
        dispatch({
            type: CHAT_ERROR,
            payload: { msg: "something went wrong", status: 500 }
        });
        console.log(err);
    }
}

// Create New Chat
export const createChat = ( customerObj, currentUserObj, history ) => async dispatch => {
    try {

        // Log the customer ID received from the frontend
        console.log('FRONTEND CUSTOMER ID');

        // Create a new chat document in the Firestore collection
        const result = await addDoc(chatsCollectionRef, {
            from_user: currentUserObj,
            to_user: customerObj,
            current_users: [currentUserObj._id, customerObj._id],
            read: [currentUserObj._id, customerObj._id],
            last_message: "",
            date: Date.now(),
            createdAt: serverTimestamp(),
            lastModified: serverTimestamp()
        });

        console.log('SENT TO FIREBASE')

        // const chatDoc = await getDoc(result);

        // console.log('CHAT OBJECT HERE!!')
        // console.log(chatDoc.data());

        // dispatch({
        //     type: CREATE_CHAT,
        //     payload: {
        //         ...chatDoc.data(),
        //         _id: chatDoc.id
        //     }
        // });
        // history.push(`/messages/${res.data._id}`);


        // --- Create Notification ---- >

            if(!(customerObj._id === currentUserObj._id)) {

                console.log('NOTIFICATION INFO')


                dispatch(createNotification(
                    {                       // formData
                        type: 'new chat',
                        chatId: result.id,
                        message: '...'
                    }, 
                    customerObj._id,   // toId
                    currentUserObj.first_name,  // first_name
                    currentUserObj.last_name,     // last_name
                    currentUserObj.img     // avatar
                ));
                
                console.log('SENT NOTIFICATION')
            }

        // --- END Create Notification ---- >

        // Redirect to the chat page with the newly created chat's ID
        window.location.href = `/messages/${result.id}`;

        // Dispatch an action to set an alert indicating the successful creation of a new conversation
        dispatch(setAlert('New Conversation Started', 'success'));

    } catch (err) {
        console.log('ERROR CREATING CHATS!!!')
        console.log(err)

        // Dispatch an action to handle the chat error, providing an error message and status
        dispatch({
            type: CHAT_ERROR,
            payload: { msg: 'Something went wrong', status: 500 }
        });
    }
};

// Delete Chat
export const deleteChat = (chat_id, history) => async dispatch => {
    try {

        // -- Query messages related to the chat
        console.log('QUERYING  MESSAGES');
        const q = query(messagesCollectionRef, where("chat", "==", chat_id));
        console.log('QUERYING  RESULT');

        // Retrieve the messages snapshot
        const msgsSnapshot = await getDocs(q);
        const msg_id_array = msgsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('MESSAGES ARRAY RESULT');

        // Iterate over the message IDs and delete each message
        msg_id_array.map(async (msg_id_obj) => {
            try {

                // Get a reference to the message document
                const docRef = doc(db, 'messages', msg_id_obj._id)
            
                console.log('DELETING MESSAGES PROCESS')

                // Delete the message document
                await deleteDoc(docRef);

            } catch (err) {
                console.log('ERROR');
                console.log(err);

                // Dispatch an alert to notify that not all chat messages could be deleted
                dispatch(setAlert('Couldn\'t delete all chat messages', 'danger'));
            }
        })

        // Get a reference to the chat document
        const chatRef = doc(db, 'chats', chat_id)

        // Delete the chat document
        await deleteDoc(chatRef);
        
        // Dispatch an action to fetch the updated user chats
        dispatch(getUserChats());

        // Redirect to the 'Messages' page
        window.location.href = "/messages";
        
        // Set an alert to inform the user that the post was deleted
        dispatch(setAlert('Chat deleted', 'okay'));
        
    } catch (err) {
        console.log('ERROR');
        console.log(err);

        // Dispatch an alert to notify that something went wrong
        dispatch(setAlert('Something went wrong', 'danger'));
    }
}

// New Message
export const addMessage = ( campus_name, formData, imgData, imgDimensions, chatId, userObj, notify_toId ) => async dispatch => {
    
    // Reference to the chat document in the Firestore database
    const chatRef = doc(db, "chats", chatId);

    console.log('SENDING MESSAGE HERE!');
    try {

        // Create Message

        console.log('FRONTEND FORM DATA');

        const {
            text,
            postId
        } = formData;

        let hasPhoto = false;

        if(imgData?.length > 0) {
            hasPhoto = true;
        }
    
        // Get fields
        const messageFields = {};
        messageFields.chat = chatId;
        if(text) messageFields.text = text;
        if(postId) messageFields.postId = postId;
        messageFields.hasPhoto = hasPhoto;
        messageFields.user_name = userObj.first_name + ' ' + userObj.last_name;
        messageFields.avatar = userObj.img;
        messageFields.user = userObj._id;
        messageFields.date = Date.now();
        messageFields.createdAt = serverTimestamp();

        // Add the message to the 'messages' collection
        const result = await addDoc(messagesCollectionRef, messageFields);


        console.log('SECCESSFULLY ADDED MESSAGE')

        // --- Add Images to Message

        if(imgData) {
            // Reference to the message document in the Firestore database
            const docRef = doc(db, 'messages', result.id)

            // Reference to the "img_gallery" collection in the message document
            const colRef = collection(docRef, "img_gallery")

            // Get message document data message reference
            const msgDoc = await getDoc(docRef);

            console.log('LOAD NEW MESSAGE DATA');

            console.log('IMG DATA');

            // Iterate over the image data array and upload each image
            imgData.map(async (img) => {
                
                const storageRef = ref(storage, `images/messages/${result.id}/${img.name}`);

                const res = await uploadBytesResumable(storageRef, img);

                console.log('Uploaded a blob or file!');

                const imgPath = await getDownloadURL(storageRef);

                const galleryData = await getDocs(colRef);
                console.log('IMG GALLERY LENGTH');
                console.log(galleryData.docs.length)

                console.log('IMG PATH');
                console.log(imgPath);

                console.log('IMG FILENAME');
                console.log(img.name);

                let orderNum = 1;
                
                if(galleryData.docs && galleryData.docs.length > 0) {
                    orderNum = galleryData.docs.length + 1
                }
                console.log('ORDER NUM');
                console.log(orderNum);

                // Find the dimensions information of the current image
                const imgInfo = imgDimensions.find(dimensionObj => dimensionObj.fileName === img.name);

                console.log('IMG INFO - DIMENSIONS');
                console.log(imgInfo);

                let newImg = {}; 

                if(imgInfo) {

                    // Create a new image object with dimensions information
                    newImg = {
                        img_path: imgPath,
                        img_name: img.name,
                        img_order: orderNum,
                        img_width: imgInfo.width,
                        img_height: imgInfo.height
                    };
                } else {

                    // Create a new image object without dimensions information
                    newImg = {
                        img_path: imgPath,
                        img_name: img.name,
                        img_order: orderNum,
                        img_width: 0,
                        img_height: 0
                    };
                }

                // Add the new image to the "img_gallery" subcollection
                const gal = await addDoc(colRef, newImg);
            });

            // Clear the editorImgFiles state
            dispatch(clearEditorImgFile());
        }

        // --- END ADD IMGS ---


        // TODO: Get Message with Images

        // Update Chats last_message && chat read by array
        console.log('UPDATING CHAT LAST MESSAGE');

        // Update chat fields
        const chatFields = {};
        if(text) {
            chatFields.last_message = text;
            chatFields.last_message_id = result.id;
        } else {
            chatFields.last_message = "Sent a photo";
            chatFields.last_message_id = result.id;
        }

        let prevChatReadArray = [];

        const chatDoc = await getDoc(chatRef);

        prevChatReadArray = [...chatDoc.data().current_users];

        const index = prevChatReadArray.indexOf(auth.currentUser.uid);

        if (index > -1) { // only splice array when item is found
            prevChatReadArray.splice(index, 1); // 2nd parameter means remove one item only
        }

        chatFields.read = prevChatReadArray;
        chatFields.lastModified = serverTimestamp();
        chatFields.date = Date.now();
           
        // Update the chat document with the new chatFields
        await updateDoc(chatRef, chatFields)

        dispatch({
            type: UPDATE_LAST_MESSAGE,
            payload: {
                ...chatDoc.data(),
                read: prevChatReadArray,
                last_message: text,
                last_message_id: result.id,
                _id: chatDoc.id
            }
        });

        // --- Create Notification ---- >

        if(!(notify_toId === userObj._id)) {
            console.log('NOTIFICATION INFO')


            dispatch(createNotification(
                {                       // formData
                    type: 'message',
                    chatId,
                    message: `${text ? text : 'Photo 📸'}`
                }, 
                notify_toId,   // toId
                userObj.first_name,  // first_name
                userObj.last_name,     // last_name
                userObj.img     // avatar
            ));
            
            console.log('SENT NOTIFICATION')
        }

        // --- END Create Notification ----

        // --- Send Email ----

        if(!(notify_toId === userObj._id)) {

            console.log('SENDING EMAIL FOR MESSAGE')

            let unRawState;
            let unRawDescription;

            if(text) {
                unRawState = EditorState.createWithContent(
                    convertFromRaw(JSON.parse(text))
                );

                unRawDescription = draftToHtml(convertToRaw(unRawState.getCurrentContent()));

                console.log('PLAIN TEXT')
            } else {
                unRawDescription = '<p>Sent a photo 📸</p>'
            }

            dispatch(sendEmail(
                {                       // formData
                    type: 'message',
                    to_userId: notify_toId,
                    from_user: {
                        first_name: userObj.first_name,
                        last_name: userObj.last_name,
                        username: userObj.username,
                        avatar: userObj.img
                    },
                    chatId: chatId,
                    message: removeHtmlTags(unRawDescription)
                }, 
                campus_name

            ));
            
            console.log('SENT EMAIL ACTION')
        }

        // --- END Send Email ---


        // dispatch({
        //     type: ADD_MESSAGE,
        //     payload: messageData.data
        // });

        // Dispatch a success alert indicating that the meesage has been sent
        // dispatch(setAlert('Message sent', 'success'));

    } catch (err) {
        console.log('ERROR SENDING MESSAGE!!!')
        console.log(err)

        // Dispatch an action to handle the chat error, providing an error msg and status
        dispatch({
            type: CHAT_ERROR,
            payload: { msg: 'Something went wrong', status: 500 }
        });
    }
};

// Get Messages by chat ID
export const getMessagesByChatId = id => async dispatch => {

    // Remove previously retrieved messages
    dispatch(clearMessages());
    
    try {

        // Create a Firestore query to retrieve messages with a specific chat ID
        const q = query(messagesCollectionRef, where("chat", "==", id), orderBy('createdAt', 'desc'));
        
        console.log('QUERY MESSAGE RESULTS');

        // Query the messages collection to get messages with a specific chat ID
        const querySnapshot = await getDocs(q);

        // Map the query snapshot to an array of message objects
        const chatMessages = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('CHAT MESSAGES ARRAY');

        console.log('SUCCESSFULLY GOT MSGS BY USER IDDD');
        
        // Dispatch an action to store the retrieved messages in the state
        dispatch({
            type: GET_MESSAGES,
            payload: chatMessages
        });
    } catch (err) {
        console.log('ERROR GETTING MSGS BY ID!!!')
        console.log(err)

        // If an error occurs, dispatch an action to set an empty array of messages in the state
        dispatch({
            type: SET_MESSAGES,
            payload: []
        })
    }
};

// Get Messages by chat ID
export const addToMessages = msgObj => async dispatch => {
    try {

         // Dispatch an action to add the message object to the state
        dispatch({
            type: SET_MESSAGE,
            payload: msgObj
        });
    } catch (err) {

        // If an error occurs, dispatch an action to set an empty array of messages in the state
        dispatch({
            type: SET_MESSAGES,
            payload: []
        })
    }
};

// Mark Chat as Read
export const markChatRead = ( chatId ) => async dispatch => {
    const chatRef = doc(db, "chats", chatId);
    try {
        // Log the chat ID received
        console.log('FRONTEND CHAT ID');

        // Update chat fields to mark the chat as read
        let chatFields = {};

        const chatDoc = await getDoc(chatRef);

        let prevChatReadArray = [];

        if(!Array.isArray(chatDoc.data().read)) {
            prevChatReadArray = [...chatDoc.data().current_users];

            const index = prevChatReadArray.indexOf(auth.currentUser.uid);

            if (index > -1) { // only splice array when item is found
                prevChatReadArray.splice(index, 1); // 2nd parameter means remove one item only
            }

        } else {
            prevChatReadArray = [...chatDoc.data().read];

            if(!prevChatReadArray.includes(auth.currentUser.uid)) return;

            const index = prevChatReadArray.indexOf(auth.currentUser.uid);

            if (index > -1) { // only splice array when item is found
                prevChatReadArray.splice(index, 1); // 2nd parameter means remove one item only
            }
        }

        chatFields.read = prevChatReadArray;
            
        // Update the chat document in the Firestore database
        console.log('--- CHAT FIELDS!!')

        await updateDoc(chatRef, chatFields)

    } catch (err) {
        console.log('ERROR MARKING CHAT READ!!!')
        console.log(err)

        // If an error occurs, dispatch an action to handle the chat error
        dispatch({
            type: CHAT_ERROR,
            payload: { msg: 'Something went wrong', status: 500 }
        });
    }
};

// Get Un-read Chats count
export const getUnreadChats = () => async dispatch => {
    console.log('GETTING UN-READ CHATS ###');
    try {

        // Log the start of the process for getting unread chats
        console.log('QUERYING UN-READ CHATS');

        // Create a query to retrieve unread chats for the current user
        const chatsQuery = query(chatsCollectionRef, where("read", "array-contains", auth.currentUser.uid));

        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(chatsQuery);

        // Get the length of the query snapshot to determine the number of unread chats
        const unreadChats = querySnapshot.docs.length;

        // Dispatch an action of type SET_UNREAD_CHATS with the payload of the unread chats count
        dispatch({
            type: SET_UNREAD_CHATS,
            payload: unreadChats
        });
    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // If an error occurs, dispatch an action of type SET_UNREAD_CHATS with the payload of 0 to indicate no unread chats
        dispatch({
            type: SET_UNREAD_CHATS,
            payload: 0
        })
    }
}

// Add like
export const addLike = (messageId, fromUserData, messageLikes, chatId, notify_toId) => async dispatch => {
    
    let likeList = [];
    // Get the current list of likes from the post data
    if(messageLikes) {
        likeList = [...messageLikes];
    }
    
    try {
        console.log('ADDING LIKE!!!!!')

        // Get Firebase Message & Likes of post collection ref
        const messageRef = doc(db, 'messages', messageId)
        // Reference to the chat document in the Firestore database
        const chatRef = doc(db, "chats", chatId);

        // Get message document data with message reference
        const msgDoc = await getDoc(messageRef);
        
        // Create the new like object
        const newLike = {
            user: {
                _id: fromUserData._id,
                username: fromUserData.username,
                first_name: fromUserData.first_name,
                last_name: fromUserData.last_name,
                img: fromUserData.img
            }
        };

        // Check if message already liked by same user
        if(likeList.filter(like => like.user._id.toString() === fromUserData._id).length > 0) {
            // Get the index of the like to remove
            const removeIndex = likeList.map(like => like.user._id.toString()).indexOf(fromUserData._id);

            const likeID = likeList[removeIndex]._id;

            // Remove the like from the message
            likeList.splice(removeIndex, 1);

            // Create array for the list of like ID's
            const id_array = [];

            likeList.map((like) => {
                id_array.push(like.user._id);
            })

            // Update the message document with the updated likes and like_id_list
            await updateDoc(messageRef, {
                likes: likeList,
                like_id_list: id_array
            })

            // Update chat fields
            const chatFields = {};
            chatFields.last_message = `${fromUserData.first_name} removed a ❤️`;
            chatFields.lastModified = serverTimestamp();
            chatFields.date = Date.now();

            // Update the chat document with the new chatFields
            await updateDoc(chatRef, chatFields)

            const chatDoc = await getDoc(chatRef);

            dispatch({
                type: UPDATE_LAST_MESSAGE,
                payload: {
                    _id: chatDoc.id,
                    ...chatDoc.data(),
                    last_message: `${fromUserData.first_name} removed a ❤️`
                }
            });
        } else {
            // const likeData = await addDoc(colRef, newLike);

            // Add the new like to the likeList
            likeList.push(newLike);

            // Create array for the list of like ID's
            const id_array = [];

            likeList.map((like) => {
                id_array.push(like.user._id);
            })

            // Update the message document with the updated likes and like_id_list
            await updateDoc(messageRef, {
                likes: likeList,
                like_id_list: id_array
            })

            console.log('SUCCESSFULLY LIKED');
            

            // Dispatch an alert to indicate that the message was saved
            dispatch(setAlert('Reaction sent.', 'okay'));

            // Update Chats last_message && chat read by array
            console.log('UPDATING CHAT LAST MESSAGE');

            let msgText = msgDoc.data().text;

            // Converts the DraftJS text to HTML and sets the converted text
            if (msgText?.includes('{"blocks":[{')) {

                let unRawState;

                if(msgText !== null) {
                    unRawState = EditorState.createWithContent(
                        convertFromRaw(JSON.parse(msgText))
                    );
                    
                    const unRawDescription = draftToHtml(convertToRaw(unRawState.getCurrentContent()));

                    msgText = unRawDescription;
                }
            }

            // Update chat fields
            const chatFields = {};
            chatFields.last_message = `${fromUserData.first_name} reacted with ❤️: ${msgText ? removeHtmlTags(msgText) : 'Photo 📸'}`;
            chatFields.lastModified = serverTimestamp();
            chatFields.date = Date.now();

            // Update the chat document with the new chatFields
            await updateDoc(chatRef, chatFields)

            const chatDoc = await getDoc(chatRef);

            dispatch({
                type: UPDATE_LAST_MESSAGE,
                payload: {
                    _id: chatDoc.id,
                    ...chatDoc.data(),
                    last_message: `${fromUserData.first_name} reacted with ❤️: ${msgText ? removeHtmlTags(msgText) : 'Photo 📸'}`
                }
            });

            // --- Create Notification ---- >
            
                if(!(notify_toId === fromUserData._id)) {
                    // Create a notification for the like action
                    dispatch(createNotification(
                        {                       // formData
                            type: 'message like',
                            chatId: chatId,
                            message: `${msgDoc.data().text ? msgDoc.data().text : 'Photo 📸'}`
                        }, 
                        notify_toId,   // toId
                        fromUserData.first_name,  // first_name
                        fromUserData.last_name,     // last_name
                        fromUserData.img     // avatar
                    ));
                }

            // END Create Notification
        }
  
    //   dispatch({
    //     type: UPDATE_POST_LIKES,
    //     payload: { id, likes: res.data }
    //   });

      
    } catch (err) {
      console.log(err)
    }
};

// Chats loading
export const setChatsLoading = () => {
    return {
        type: CHATS_LOADING
    }
}

// Chat loading
export const setChatLoading = () => {
    return {
        type: CHAT_LOADING
    }
}

// Remove all Chats
export const clearChats = () => dispatch => {

    // Dispatch an action of type CHATS_LOADING to indicate that chats are being cleared
    dispatch(setChatsLoading());

    // Dispatch an action of type CLEAR_CHATS to remove all chats from the state
    dispatch({
        type: CLEAR_CHATS
    });

}

// Remove all Messages
export const clearMessages = () => dispatch => {

    // Dispatch an action of type CHAT_LOADING to indicate that messages are being cleared
    dispatch(setChatLoading());

    // Dispatch an action of type CLEAR_MESSAGES to remove all messages from the state
    dispatch({
        type: CLEAR_MESSAGES
    });

}

// Set State of Text Editor
export const setEditorText = editorData => dispatch => {

    dispatch({
        type: UPDATE_EDITOR_TEXT,
        payload: editorData
    });
};

// Clear Editor Text
export const clearEditorText = () => dispatch => {
    dispatch({
        type: CLEAR_EDITOR_TEXT
    });

}

// Set State of Editor Img
export const setEditorImgFile = fileData => dispatch => {

    dispatch({
        type: UPDATE_EDITOR_IMG_FILE,
        payload: fileData
    });
};

// Clear Editor File State
export const clearEditorImgFile = () => dispatch => {
    dispatch({
        type: CLEAR_EDITOR_IMG_FILE
    });

}

// Add 1 Temporary Image url to the img_url state
export const setChatImgUrl = urlData => dispatch => {

    dispatch({
        type: UPDATE_IMG_URL,
        payload: urlData
    });
};

// Remove 1 url from img_url
export const removeChatImgUrl = () => dispatch => {
    dispatch({
        type: REMOVE_IMG_URL
    });
};



// --------------------------------------
// --- Datebase minipulation functions

export const addLastModifiedFieldToChats = () => async dispatch => {
    // const updateChatsLastModified = async () => {
    //     console.log('UPDATING: LAST MODIFIED CHATS NOW...');
    //     dispatch(setAlert('UPDATING LAST MODIFIED CHATS NOW...', 'okay'));

    //     const querySnapshot = await getDocs(chatsCollectionRef);
        
    //     const updatePromises = querySnapshot.docs.map(async (documentSnapshot) => {
    //         if(!documentSnapshot.data().lastModified) {
    //             const createdAt = documentSnapshot.data().createdAt;
        
    //             const chatRef = doc(db, 'chats', documentSnapshot.id);
    //             await updateDoc(chatRef, {
    //                 lastModified: createdAt
    //             });
    //         }
    //     });
        
    //     await Promise.all(updatePromises);
        
    //     dispatch(setAlert('All chats updated successfully', 'okay'));

    //     console.log('All chats updated successfully.');
    // };
      
    // // Call the function to update all the chats
    // await updateChatsLastModified();

}

import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Redux
import { connect } from 'react-redux';

// Actions
import { getUnreadCount } from '../../actions/notificationActions';
import { getUnreadChats } from '../../actions/chatActions';
import { setPosts, setPostsLoading } from '../../actions/postActions';
import { setUsers } from '../../actions/authActions';
import { toggleAuthModal } from '../../actions/navActions';

// Routing
import { Link, useHistory, useLocation } from 'react-router-dom';

// Firebase
import { db } from '../../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// ALGOLIA SEARCH
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';

// Components - imported
import SearchWidgets from '../Widgets/SearchWidgets';
import { Avatar, Button } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';
import Spinner from '../common/Spinner';
import Avatar_For_Headers from '../common/Avatar_For_Headers';
import Search_Input from '../common/Search_Input';

// Modal
import Modal from '../modal/Modal';
import ModalContainer from '../modal/ModalContainer';

// Icons - material UI
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import SignalCellularAltOutlinedIcon from '@material-ui/icons/SignalCellularAltOutlined';

const SearchLayout = ({ 
    getUnreadCount,
    getUnreadChats,
    setPosts,
    setPostsLoading,
    setUsers,
    toggleAuthModal,
    children, 
    user, 
    page, 
    auth, 
    nav: {
        campus_name,
        campus_id,
        campus_privacy
    }, 
    post: { post }, 
    chat, 
    notification, 
    notificationsPage, 
    sideNav, 
    handleSlideMenu, 
    totalPosts,
    handleScroll,
    lastPageDoc,
    setLastPageDoc
}) => {

    // --- Get Current URL and extract URL variables --- 

    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("q"); // extract the search keyword from "q" parameter
    const display_filter = url.searchParams.get("show"); // extract the which search tab is active from "display_filter" parameter

    // --- END: Get Current URL and extract URL variables --- 

    // --- Algolia Stuff ---

    const searchClient = algoliasearch('L05VYIT7Y1', '9d40c9c36435a68fa7223c9cfc436d04'); // change the secret key (2nd) to specific api key

    const searchIndex = searchClient.initIndex('posts');
    const userIndex = searchClient.initIndex('users');

     // --- END: Algolia Stuff ---

    // --- States ---

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    const [badgeValue, setBadgeValue] = useState(0);

    // Toggle Dropdwon
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [authDropdown, setAuthDropdown] = useState(false);

    // --- END: States ---

    // Determine if the window is in mobile or tablet view
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Firebase collection ref
    const postsCollectionRef = collection(db, "posts");

    // --- Page Listeners ---

    useEffect(() => {
        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Apply CSS classes to the feed header based on the value of "sideNav"
    useEffect(() => {
        if(!isMobile) {
            if(sideNav) {
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                    return "feed__header";
                }
            } else {
                if(document.getElementById('feed-header') !== null) {
                    setTimeout(() => {
                        document.getElementById('feed-header').classList.add("active");
                    }, 700)
                }
            }
        } else {
            if(document.getElementById('feed-header') !== null) {
                document.getElementById('feed-header').classList.remove("active");
                return "feed__header";
            }
        }
    }, [sideNav, isMobile])

    // Fetch unread notification count and unread chat count when the authenticated user changes
    useEffect(() => {
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [auth.user]);

    // Update the badge value based on the counts of unread notifications and unread chats
    useEffect(() => {
        setBadgeValue(notification.num_unread + chat.num_unread_chats);
    }, [notification.num_unread, chat.num_unread_chats])

    useEffect(() => {

        if(filter === null) return;
        handleSearch(filter);
        setLastPageDoc(0);

    }, [filter, display_filter, auth.loading])  

    // --- END: Page Listeners ---

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Redirect the user to the previous page or a specific URL when the "goBack" function is called
    const history = useHistory();
    const goBack = () => {

        let previousURL = document.referrer;

        var pathArray = previousURL.split( '/' );
        var host = pathArray[2];

        console.log(pathArray);

        // Check the source of the previous URL and navigate accordingly
        if((typeof(host) !== 'undefined' && host.length) && (host.toLowerCase().includes("oubuysell") || host.toLowerCase().includes("localhost:3000") || host.toLowerCase().includes("mycontacts-9ba5a"))) {
            if(pathArray[4] && pathArray[4].toLowerCase().includes("set-up")) {
                window.location.href = '/home';
            } else if(previousURL.toLowerCase().includes("/search")) {
                history.goBack();
            } else if(pathArray[3] && pathArray[4] && pathArray[3].toLowerCase().includes("messages")) {
                window.location.href = '/home';
            } else if(previousURL.toLowerCase().includes("?filter=welcome")) {
                window.location.href = '/home';
            } else {
                window.location.href = '/home';
            }
        } else {
            window.location.href = '/home';
        }

    }

    // --- Submit & Handle Search --- 
    
    // Get initial search results
    const handleSearch = async (queryText) => {
        try {

            if(queryText === '') return;

            if(display_filter === 'people') {
                const results = await userIndex.search(queryText, {
                    page: lastPageDoc,
                    hitsPerPage: 10
                });

                console.log('SEARCH RESULT');
                console.log(results);

                // Fetch initial users
        
                console.log('UPDATING USERS...');
                const tempUserList = results.hits.map((doc) => ({...doc, _id: doc.objectID}));

                setUsers(tempUserList);

                // Get the last visible document for the next load
                setLastPageDoc(lastPageDoc + 1);

            } else {
                setPostsLoading();

                const results = await searchIndex.search(queryText, {
                    page: lastPageDoc,
                    hitsPerPage: 10
                });

                console.log('SEARCH RESULT');
                console.log(results);

                // setPosts(results.hits.map((doc) => ({...doc, _id: doc.objectID})));

                // Fetch initial posts
        
                console.log('UPDATING POSTS...');
                const tempPostList = results.hits.map((doc) => ({...doc, _id: doc.objectID}));

                // Recursively fetch comments of comments
                const fetchCommentsOfComments = async (comments) => {
                    for (let i = 0; i < comments.length; i++) {
                    const comment = comments[i];
                    
                    // Create a query to retrieve comments where the post_commented_on field matches the comment ID, ordered by creation date in descending order
                    const commentOfCommentQuery = query(postsCollectionRef, where("post_commented_on", "==", comment._id), orderBy('createdAt', 'asc'));
                    
                    // Execute the query and retrieve the query snapshot
                    const commentOfCommentSnapshot = await getDocs(commentOfCommentQuery);
                    
                    // Map through the document snapshots in the query snapshot and extract the data and ID for each comment of comment
                    const commentsOfComments = commentOfCommentSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
                    
                    // Append the comments of comments to the current comment
                    comment.commentsOfComments = commentsOfComments;
                    
                    // Recursively fetch comments of comments for the current comment
                    await fetchCommentsOfComments(commentsOfComments);
                    }
                };
                
                // Fetch comments of comments for the initial set of comments
                await fetchCommentsOfComments(tempPostList);

                setPosts(tempPostList);

                // Get the last visible document for the next load
                setLastPageDoc(lastPageDoc + 1);
            }

        } catch (error) {
            console.log(error);
            setPosts([]);

            if(display_filter === 'people') {
                setUsers([]);
            }
        }
    }

    // --- END: Submit & Handle Search --- 

    // --- Get users full name ---

    let user_name;

    if(!auth.loading && auth.isAuthenticated) {
        user_name = `${auth.user.first_name} ${auth.user.last_name && auth.user.last_name}`
    }

    // --- END: Get users full name ---

    return (
        <Fragment>
            <div className="feed__container">
                <div onScroll={handleScroll} className="feed">

                    {/**Header */}
                    <div className="feed__header" id="feed-header">
                            
                        {/*
                            Render the header content based on the current page and filter
                            Display a menu icon for mobile devices and handle slide menu if clicked
                            Display the appropriate header text based on the filter value
                            Display a badge with the badgeValue if it's greater than 0 and it's a mobile device
                        */}

                        <div className="shopFeed__search">

                            {/* Shopping Cart Icon */}
                            <div onClick={goBack} className="shopFeed__backBtn">
                                <div>
                                    <ArrowBackIcon />
                                </div>
                            </div>

                            {/* Alerts Badge: displayed if it's a mobile device and badgeValue is greater than 0 */}
                            {isMobile && badgeValue > 0 ? (
                                <span className="feed-header-badge defaultBadge">
                                    {badgeValue}
                                </span>
                            ) : null}

                            {/* Search Input */}
                            <Search_Input />

                            {/* --- Avatar Icon --- */}
                            <div className="shopFeed__cog">
                                <Avatar_For_Headers />
                            </div>
                            {/* --- END: Avatar Icon --- */}
                        </div>          
                    </div>

                    {/* Display layout content (posts, notifications, etc.) */}
                    {children}
                </div>

                {/* Display 'website stats' box (widgets) if not tablet */}
                {/* {!isTablet && <SearchWidgets />} */}
            </div>
        </Fragment>
    )
}

SearchLayout.propTypes = {
    // Prop type validation for authentication
    auth: PropTypes.object.isRequired,
    // Prop type validation for navigation
    nav: PropTypes.object.isRequired,
    // Prop type validation for notification
    notification: PropTypes.object.isRequired,
    // Prop type validation for chat
    chat: PropTypes.object.isRequired,
    // Prop type validation for post
    post: PropTypes.object.isRequired,
    // Prop type validation for function to fill the posts array with docs
    setPosts: PropTypes.func.isRequired,
    setPostsLoading: PropTypes.func.isRequired,
    // Prop type validation for function to fill the users array with docs
    setUsers: PropTypes.func.isRequired,
    toggleAuthModal: PropTypes.func.isRequired,
    // Prop type validation for function to get unread notifications
    getUnreadCount: PropTypes.func.isRequired,
    // Prop type validation for function to get unread chats
    getUnreadChats: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    // Mapping the authentication state
    auth: state.auth,
    // Mapping the navigation state
    nav: state.nav,
    // Mapping the notification state
    notification: state.notification,
    // Mapping the chat state
    chat: state.chat,
    // Mapping the post state
    post: state.post,
})

export default connect(mapStateToProps, {
    
    // Connecting actions to the component
    setPosts,
    setPostsLoading,
    setUsers,
    toggleAuthModal,
    getUnreadCount, 
    getUnreadChats 
})(SearchLayout);
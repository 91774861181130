import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types'

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { getUnreadChats } from '../actions/chatActions';
import { getUnreadCount } from '../actions/notificationActions';

// Layout Components
import MorePage_Nav from '../components/morePage/MorePage_Nav';

// Actions - imported
import { toggleSideNav, setPage, add_To_Recent_History, goBack } from '../actions/navActions';

const MorePageNav = ({ 
    getUnreadChats,
    getUnreadCount,
    toggleSideNav, 
    setPage, 
    add_To_Recent_History,
    goBack,
    nav, 
    auth, 
    chat,
    notification 
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    useEffect(() => {

        // Set the current page to 'settings'
        setPage("more page");    
        add_To_Recent_History(url.pathname);  // current url  
    }, [])

    // Initialize Mixpanel and track settings Help page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("More Page Nav View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <Fragment>
            <div className="inbox__container">

                {/* Render the MorePage_Nav component */}
                <MorePage_Nav 
                    sideNav={nav.sideNav} 
                    handleSlideMenu={toggleSideNav} 
                    auth={auth} page={nav.page} 
                    chat={chat}
                    notification={notification} 
                    getUnreadChats={getUnreadChats}
                    getUnreadCount={getUnreadCount}
                    goBack={goBack}
                />
            </div>
        </Fragment>
    )
}

MorePageNav.propTypes = {
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    getUnreadChats: PropTypes.func.isRequired,
    getUnreadCount: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav,
    chat: state.chat,
    notification: state.notification
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    toggleSideNav, 
    setPage, 
    getUnreadChats, 
    getUnreadCount,
    add_To_Recent_History,
    goBack,
})(MorePageNav);

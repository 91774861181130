import React from 'react'
import PropTypes from 'prop-types'

const Readme = props => {
    return (
        <div className='readme_body'>
            <main>
        <h1 id="real-time-face-detection-using-computer-vision">Real-Time Face Detection using Computer Vision</h1>
<p>This project demonstrates real-time face detection using Python and OpenCV. It utilizes a pre-trained Haar cascade classifier to detect faces from live webcam footage and overlays rectangles around detected faces.</p>
<p><img src="https://github.com/tunde262/face_detector_opencv/blob/main/assets/thumbnail.png?raw=true" alt="thumbnail" /></p>
<h2 id="project-goal">Project Goal</h2>
<p>The goal of this project was to explore the applications of computer vision in object detection and build an app using OpenCV to process video for face detection.</p>
<h2 id="features">Features</h2>
<ul>
<li>Real-time face detection using a webcam feed.</li>
<li>Drawing rectangles around faces as they are detected.</li>
<li>Designed to be easy to run and modify for other applications of computer vision.</li>
</ul>
<h2 id="code-explanation">Code Explanation</h2>
<ul>
<li>The program uses OpenCV’s <code>CascadeClassifier</code> with a pre-trained Haar Cascade model to detect faces.</li>
<li>The webcam feed is captured using <code>cv2.VideoCapture(0)</code>, and each frame is converted to grayscale before passing through the detection algorithm.</li>
<li>Detected faces are highlighted using rectangles (<code>cv2.rectangle()</code>).</li>
<li>The program runs in a loop, processing the video frames until the <code>q</code> key is pressed.</li>
</ul>
<h2 id="requirements">Requirements</h2>
<ul>
<li>Python 3.x</li>
<li>OpenCV (<code>opencv-python</code> package)</li>
<li>NumPy (<code>numpy</code> package)</li>
</ul>
<h2 id="installation">Installation</h2>
<ol>
<li>Clone the repository:</li>
</ol>
<pre><code class="hljs bash language-bash">
    git <span class="hljs-built_in">clone</span> https://github.com/tunde262/face_detector_opencv.git
   <br/><span class="hljs-built_in">cd</span> face_detector_opencv</code></pre>

   <ol start="2">
<li>Install the necessary dependencies:</li>
</ol>
<pre><code class="hljs bash language-bash">
   pip install opencv-python numpy
   </code></pre>
<h2 id="how-to-run">How To Run</h2>
<ol>
<li><p>Make sure your webcam is connected and functional.</p></li>
<li><p>Run the Python script to start the program:</p></li>
<pre><code class="hljs bash language-bash">
python face_detector.py</code></pre>
<li><p>The webcam feed will appear with rectangles drawn around any detected faces. Press the <code>q</code> key to exit the program.</p></li>
</ol>
            </main>
        </div>
  )
}

Readme.propTypes = {}

export default Readme;

import React from 'react';
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Layout Wrapper
import ModalPage from '../components/layout/ModalPage';

// Components - imported
import { Button } from '@material-ui/core';

// Icons -imported
import TwitterIcon from '@material-ui/icons/Twitter';

import logo from '../utils/imgs/bandwagon_logo.png';

const Landing = ({ auth: { user }}) => {
    let history = useHistory();

    // On login btn click redirect to login page
    const loginLink = () => {
        history.push('/login')
    }

    // On register btn click redirect to register page
    const registerLink = () => {
        history.push('/register')
    }

    // If user is already logged in Redirect
    if(user) {
        window.location.href = "/home";
        // history.push("/home");
    }

    return (
        <ModalPage>
            {/* <TwitterIcon className="modal__twitterIcon" /> */}

            {/* Logo image */}
            <img className="modal__twitterIcon" src={logo} style={{maxHeight: '40px'}} alt="logo" />
            
            {/* Main Text */}
            <div>
                <h2>University of Oklahoma</h2>
                <hr/>
                <h2>Buy / Sell</h2>
            </div>
            {/* <p>You can always log back in at any time. If you just want to switch accounts, you can do that by adding an existing account.</p> */}
            
            {/* Login and registration buttons */}
            <div className="btnRow">
                <Button 
                    onClick={loginLink}
                    variant="outlined" 
                    className="sidebar__tweet grey"
                >
                    Log in
                </Button>
                <Button 
                    onClick={registerLink}
                    variant="outlined" 
                    className="sidebar__tweet"
                >
                    Sign up
                </Button>
            </div>
        </ModalPage>
    )
}

Landing.propTypes = {
    auth: PropTypes.object.isRequired, // Object prop representing the authentication state of the user
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth
});

export default connect(mapStateToProps, null)(Landing);

import { combineReducers } from 'redux';
import alert from './alertReducer';
import auth from './authReducer';
import nav from './navReducer';
import post from './postReducer';
import chat from './chatReducer';
import notification from './notificationReducer';
import search from './searchReducer';
import game from './gameReducer';
import project from './projectReducer';

export default combineReducers({
    alert,
    auth,
    nav,
    post,
    chat,
    notification,
    search,
    game,
    project
});
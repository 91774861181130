import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import moment from 'moment';
import { format, parseISO, fromUnixTime, formatDistanceToNow } from 'date-fns';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

// Firebase
import { db } from '../../config/firebase-config';
import { collection, getDocs, getDoc, doc, query, where } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';


// Actions
import { addLike, deletePost } from '../../actions/postActions';
import { getUserById } from '../../actions/authActions';
import { createChat } from '../../actions/chatActions';
import { toggleAuthModal } from '../../actions/navActions';
import { setAlert } from '../../actions/alertActions';

// components - material UI
import { Avatar, Button } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';
import Post from '../Post/Post';

import ImageBlock from '../common/ImageBlock';

// Icons - material UI
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import RepeatIcon from '@material-ui/icons/Repeat';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';

// Modal
import Modal from '../modal/Modal';
import ModalContainer from '../modal/ModalContainer';

// DraftJS
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { Fragment } from 'react';
import Verification_Badge from '../common/Verification_Badge';

const Detail = ({
        post,
        auth, 
        setAlert,
        addLike,
        deletePost,
        getUserById,
        createChat,
        toggleAuthModal,
        toggleCommentModal,
        image,
        history,
        isTablet,
        commentList,
        likeList,
        setScrollDirection,
        setOldScrollY,
        oldScrollY
    }) => {
        
        // Tracks the like status of post
        const [liked, setLiked] = useState(false);
        // Flag for checking if like is properly updated
        const [checkLike, setCheckLike] = useState(false);

        // --- Convert DraftJS text to HTML text

        // Tracks whether the text has been converted
        const [gotText, setGotText] = useState(false);
        // Stores the converted text
        const [textState, setTextState] = useState(null);

        // Check if post has image - for font size reasons
        const [hasImg, setHasImg] = useState(false);
        // Flag for checking if hasImg is properly updated
        const [checkHasImg, setCheckHasImg] = useState(false);

        // Toggle Dropdwon
        const [dropdown, setDropdown] = useState(false);
        const [shareDropdown, setShareDropdown] = useState(false);

        // Stores the height of a toggle menu
        const [menuHeight, setMenuHeight] = useState(null);

        // Modal toggles
        const [deleteModal, setDeleteModal] = useState(false);
        const [messageModal, setMessageModal] = useState(false);

        // Tracks the status of copying
        const [copied, setCopied] = useState(false);
        
        // Controls the visibility of a copied modal
        const [copiedModal, setCopiedModal] = useState(false);

        // --- Parent Posts for comments ------
        const [parentPosts, setParentPosts] = useState([]);
        const [gotParentPosts, setGotParentPosts] = useState(false);

        const [gotTopNav, setGotTopNav] = useState(false);

        // --- END Parent Posts for comments

        // Get the current URL and extract the "redirect" parameter
        const url_filter = (window.location.href);
        const url = new URL(url_filter);
        const showMessageModal = url.searchParams.get("redirect");


        useEffect(() => {
            if(post) {

                // --- If the showMessageModal flag is set to 'true', 
                // --- toggle the message modal and redirect to the post

                if(showMessageModal === 'true') {
                    toggleMessageModal();
    
                    history.push(`/post/${post._id}`)
                }
            }

        }, [post])

        useEffect(() => {

            if(parentPosts.length > 0) {
                // Scroll to the top of the main postDetail component and below all parent posts
                executeScroll();
            }

        }, [gotParentPosts])

        useEffect(() => {

            // Set a timeout to hide the copied modal after 2 seconds
            setTimeout(() => {
                setCopiedModal(false);
            }, 2000);

        }, [copiedModal]);

        useEffect(() => {

            // Set a timeout to hide the share dropdown and reset the copied flag after 1 second
            setTimeout(() => {
                setShareDropdown(false);
                setCopied(false);
            }, 1000);
        }, [copied]);

        // Fetch the parent posts and put them in a state array
        useEffect(() => {
            if (!gotParentPosts) {

              const fetchParentPosts = async (postData) => {

                let postDocument = postData;
        
                while (postDocument.post_commented_on !== null) {

                  const parentPostRef = doc(db, 'posts', postDocument.post_commented_on);

                  try {

                    const parentPostSnapshot = await getDoc(parentPostRef);

                    setParentPosts((state) => [
                        ...state,
                        { ...parentPostSnapshot.data(), _id: parentPostSnapshot.id, commentsOfComments: [{}] }, // add commentsOfComments w/ empty obj to display the thread line in the post component
                    ]);
        
                    postDocument = parentPostSnapshot.data();

                  } catch (error) {

                    console.log('ERROR');
                    console.log(error);
                    break;
                  }
                }
              };
        
              fetchParentPosts(post)
                .then(() => {
                  setGotParentPosts(true);
                })
                .catch((error) => {
                  console.log('ERROR');
                  console.log(error);
                });
            }
          }, [gotParentPosts, post]);

        const handleLike = (postId) => {

            // If the user is authenticated, add a like to the post and toggle the liked state
            if(auth.user) {
                addLike(null, postId, auth.user, post);
                setLiked(!liked);
            } else {

                // If the user is not authenticated, fetch the user by ID and toggle the authentication modal
                getUserById(post.user._id);
                toggleAuthModal('like');
            }
        }

        // Redirect to the create comment page for the current post
        const redirectComment = () => {
            history.push(`/create/comment/${post._id}`);
        }

        // Copy Modal logic

        const toggleCopiedModal = () => {

            // --- Close other dropdowns and set the copied alert message

            setDropdown(false);
            setShareDropdown(false);
            
            // setCopiedModal(!copiedModal);
            setAlert('Copied to clipboard', `${!auth.loading && auth.isAuthenticated ? 'okay' : 'success'}`);
        }

        // Delete Modal logic

        const toggleDeleteModal = () => {

            // Close other dropdowns and toggle the delete modal
            setDropdown(false)
            setDeleteModal(!deleteModal);
        }
        
        const handleDelete = () => {
            if(post) {
                let stored_id = post._id

                // Call the deletePost function to delete the post
                deletePost(post._id);

                // Mixpanel event tracking for deleting a post
                if (process.env.NODE_ENV === 'production') {
                    mixpanel.track("Delete Post", {
                        "Post ID": `${stored_id}`
                    });
                }
            }
    
            setDeleteModal(false);

            // Redirect to the home page after deleting the post
            // window.location.href = "/home";
        }

        // End

        // Message Logic

        const toggleMessageModal = () => {

            // Get user by ID if the message modal is not currently open
            if(!messageModal) getUserById(post.user._id);
            
            setMessageModal(!messageModal);
        }
    
        const continueMessage = () => {
            if(auth.user) {

                // If the user is authenticated, create a new chat
                createNewChat();
            } else {

                // If the user is not authenticated, close the message modal and show the authentication modal
                setMessageModal(false);
                toggleAuthModal('message');
            }
        }

        const createNewChat = async () => {
            const chatsCollectionRef = collection(db, "chats");
    
            // Check if chat with 2 users already exists by chat ID
    
            console.log('CHECK USER CHAT HERE')
            
            // Query chats where the current user is the from_user and the modal user is the to_user
            const chatsQuery = query(chatsCollectionRef, where("from_user._id", "==", auth.user._id), where("to_user._id", "==", auth.modalUser._id));

            // Query chats where the modal user is the from_user and the current user is the to_user
            const otherChatsQuery = query(chatsCollectionRef, where("to_user._id", "==", auth.user._id), where("from_user._id", "==", auth.modalUser._id));
    
            // Query chats where the current user is the from_user and the modal user is the to_user
            const chatsSnapshot = await getDocs(chatsQuery);

            // Map the document snapshots to an array of chat objects, including the document ID
            const chats = chatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
    
            // Query chats where the modal user is the from_user and the current user is the to_user
            const otherChatsSnapshot = await getDocs(otherChatsQuery);

            // Map the document snapshots to an array of chat objects, including the document ID
            const otherChats = otherChatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
    
            console.log('CHECKING CHATS DATA');
            console.log(chats);
            
            console.log('CHECKING OTHER CHATS DATA');
            console.log(otherChats);
    
            console.log('CHECK USER CHAT HERE PART 2')
    
            // If array length > 0 then there is already existing chat & redirect instead
          
            if(chats.length > 0) {
                history.push(`/messages/${chats[0]._id}`);
            } else if (otherChats.length > 0) {
                history.push(`/messages/${otherChats[0]._id}`);
            } else {

                // Create a new chat if no existing chat is found
                createChat(auth.modalUser, auth.user, history);
            }
    
            setMessageModal(!messageModal);
            
            console.log('SHOULD BE DONE CREATING');
        }

        // End

        const capitalizeFirstLetter =(string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    
        // Check if the authenticated user exists and the checkLike flag is false
        if(auth.user && !checkLike) {

            // Filter the likes array of the post to check if the authenticated user has liked the post
            if(post.likes.filter(like => like.user._id.toString() === auth.user._id).length > 0){

                // If the authenticated user has liked the post, set the liked state to true
                setLiked(true);
            }
            
            // Set the checkLike flag to true to indicate that the check has been performed
            setCheckLike(true);
        }  

        // Check if the post exists and the gotText flag is false
        if (post && !gotText) {
            console.log('TEXT BLOCK')
    
            let unRawState;
    
            // Check if the post text is not null
            if(post.text !== null) {

                // Convert the raw content of the post text to EditorState
                unRawState = EditorState.createWithContent(
                    convertFromRaw(JSON.parse(post.text))
                );
            
                console.log(unRawState)
    
                console.log(draftToHtml(convertToRaw(unRawState.getCurrentContent())))
                
                // Convert the EditorState to HTML description and update the state
                const unRawDescription = draftToHtml(convertToRaw(unRawState.getCurrentContent()));
                setTextState(unRawDescription);
            }
    
            
            // let editorState = post.text
            // const rawContentState = editorState.getCurrentContent();
     
            // const markup = draftToHtml(
            //     rawContentState
            // );
    
            // console.log(markup)
    
            // Set the gotText flag to true to indicate that the text retrieval has been completed
            setGotText(true)
        }

        const myRef = useRef(null)

        // run this function from an event handler or an effect to execute scroll 
        const executeScroll = () => {
            myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        let parentPostList;

        // Check if posts are null or not yet fetched
        if(post !== null && gotParentPosts) {
            if(parentPosts.length > 0) {

                // Iterate through the parentPosts array and render each post component
                parentPostList = parentPosts.map(parentPost => {
                    return (
                        <Post key={parentPost._id} postDoc={parentPost} executeScroll={executeScroll} />
                    )
                })
            }
        }

        // --- ImageKit (img optimization service) ---

        let optimized_profile_img_path;

        if(post.user.img) {

            // Replace the firebase URL with ImageKit.io URL-endpoint
            optimized_profile_img_path = post.user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
        }

        return (
            <Fragment>
                {/* Display parent posts */}
                {parentPostList && parentPostList}

                <div className="postPage">

                    {/* Solution to fix the scrollIntoView going too far problem - see the 'myRef' */}
                    {parentPostList && (
                        <div ref={myRef} style={{height:'70px', width:'100px', marginTop:'-70px'}}></div>
                    )}

                    {/* More Button - trigger dropdown */}
                    <div onClick={() => setDropdown(!dropdown)} className={`post__moreBtn ${parentPostList ? 'has_parent_post' : ''}`}>
                        <MoreHorizIcon />
                    </div>

                    {/* More btn Dropdown Menu */}
                    <div className={dropdown ? "edit-dropdown active" : "edit-dropdown"} style={{height: menuHeight}}>
                        <div className="menu">

                            {/* Copy Link Menu Item */}
                            <CopyToClipboard 
                                text={`${process.env.REACT_APP_DOMAIN}/post/${post._id}?redirect=true`}
                                onCopy={toggleCopiedModal}
                            >
                                <div className="menu-item">
                                    {!copied ? (
                                        <Fragment>
                                            <LinkIcon />
                                            Copy link
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <CheckIcon />
                                            Copied!
                                        </Fragment>
                                    )}
                                </div>
                            </CopyToClipboard>

                            {/* Delete Menu Item (shown only for authenticated user who created the post) */}
                            {auth.user && post.user._id === auth.user._id ? (
                                <div onClick={toggleDeleteModal} className="menu-item danger">
                                    <DeleteIcon />
                                    Delete
                                </div>
                            ) : null}
                        </div>
                    </div>

                    {/* Hidden Overlay for more btn dropdown */}
                    <div
                        className={`hidden-overlay ${dropdown ? "show" : ""}`}
                        onClick={() => setDropdown(!dropdown)}
                    />

                    {/* Detail Post Header - top */}
                    <div className="postPage__header">

                        {/* Display the user avatar - links to profile page */}
                        <div onClick={() => history.push(`/${post.user.username}`)} className="postPage__avatar">

                            {post.user.img ? (
                                <Avatar src={optimized_profile_img_path && optimized_profile_img_path} />
                            ) : (
                                <DefaultAvatar name={`${post.user.first_name} ${post.user.last_name}`} />
                            )}
                        </div>
                        <div className="postPage__headerText">
                            <h3>
                                {/* Display the post user's username */}
                                <span>
                                    <Link to={`/${post.user.username}`}>
                                        <span>{post.username && post.username + ' '} </span>

                                        {/* Display the verified badge if the user is verified */}
                                        <span className="postPage__headerSpecial">
                                            <Verification_Badge user={post.user} />
                                        </span>
                                    </Link>
                                </span>
                            </h3>
                            <p>{formatDistanceToNow(post.date)} ago</p>
                        </div>
                    </div>

                    {/* Detail Post Body - bottom */}
                    <div className="postPage__body">

                        {/* Render the post text content */}
                        {textState !== null && (
                            <div className={`postPage__headerDescription ${hasImg ? 'withImgs' : ''} `}>
                                {/* <p>{post.text ? post.text : null}</p> */}

                                {/* Render the converted HTML text description */}
                                <div dangerouslySetInnerHTML={{__html: textState}} />
                            </div>
                        )}
                        
                        {/* <div className="postPage__img">
                            {post.img && <img src={post.img} alt="" />}
                        </div> */}

                        {/* Display the post images if available */}
                        <ImageBlock detailPost={post} setHasImg={setHasImg} setCheckHasImg={setCheckHasImg} checkHasImg={checkHasImg} />

                        {hasImg && (
                            // Spacing Element
                            <div style={{marginTop: '240px'}}></div>
                        )}
                        {/* Display the post timestamp */}
                        <div className="postPage__timeStamp">
                            <p>{format(post.date, "h:mm a")}</p>
                            <p>{format(post.date, "MMM do")}</p>
                        </div>
                        
                        {/* Render the "Send Message" button based on user authentication */}
                        {auth.user && post.user._id !== auth.user._id && post ? (
                            <Button onClick={toggleMessageModal} variant="outlined" className="sidebar__tweet" fullWidth>Send Message</Button>
                        ) : null} 
                        
                        {!auth.user ? (
                            <Button onClick={toggleMessageModal} variant="outlined" className="sidebar__tweet" fullWidth>Send Message</Button>
                        ) : null} 

                        {/* Render the post statistics */}
                        {/* {(commentList?.length > 0 || post.likes.length > 0) && (
                            <div className="postPage__stats">

                                {commentList.length > 0 && (
                                    <div className="postPage__stat">

                                       
                                        <span>{commentList.length}</span>
                                        &nbsp;
                                        <span style={{color: 'rgb(83, 100, 113)'}}>Comments</span>

                                    </div>
                                )}

                             
                                {post.likes.length > 0 && (
                                    <div className="postPage__stat">

                                    
                                        <a href={`/post/${post._id}/likes`}>
                                            <span>{post.likes.length}</span>
                                            &nbsp;
                                            <span style={{color: 'rgb(83, 100, 113)'}}>Likes</span>
                                        </a>
                                    </div>
                                )}
                            </div>
                        )} */}

                        <div className="postPage__actions">
                            <div>

                                {/* Comment button */}
                                <button 
                                    onClick={() => {
                                        if(auth.user) {
                                            if(isTablet) {
                                                redirectComment()
                                            } else {
                                                toggleCommentModal()
                                            }
                                        } else {
                                            getUserById(post.user._id);
                                            toggleAuthModal('comment');
                                        }
                                    }}                   
                                    className="secondaryBtn post"
                                >
                                    <div>
                                        {/* Btn Icon */}
                                        <ChatBubbleOutlineIcon fontSize="small" />
                                        {/* Btn Text */}
                                        <span>Comment</span>
                                    </div>
                                </button>
                            </div>
                            <div className={liked ? "active" : "" } onClick={() => handleLike(post._id)}>

                                {/* Like button */}
                                <button className={liked ? "secondaryBtn post active" : "secondaryBtn post like" }>
                                    <div>

                                        {/* Render the like icon based on whether the post is liked */}
                                        {liked ? <FavoriteIcon fontSize="small" /> : <FavoriteBorderIcon fontSize="small" />}

                                        {/* Btn Text w/ like qty */}
                                        <span>{liked ? 'Saved!' : 'Save'} {post.likes.length > 0 && (
                                            <span style={liked ? {display:'none'}: {fontSize:'11px'}}>({post.likes.length})</span>
                                        )}</span>
                                    </div>
                                </button>
                            </div>
                        
                            
                            <div style={{position: 'relative'}}>

                                {/* Share button */}
                                <button onClick={() => setShareDropdown(!shareDropdown)} className="secondaryBtn post share">
                                    <div>
                                        {/* Btn Icon */}
                                        <ShareIcon fontSize="small" />
                                        {/* Btn Text */}
                                        <span>Share</span>
                                    </div>
                                </button>

                                {/* Share btn Dropdown menu */}
                                <div className={shareDropdown ? "edit-dropdown active" : "edit-dropdown"} style={{height: menuHeight}}>
                                    <div className="menu">

                                        {/* Copy post link to clipboard */}
                                        <CopyToClipboard 
                                            text={`${process.env.REACT_APP_DOMAIN}/post/${post._id}?redirect=true`}
                                            onCopy={toggleCopiedModal}
                                        >
                                            <div className="menu-item">
                                                {!copied ? (
                                                    <Fragment>
                                                        <LinkIcon />
                                                        Copy link
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <CheckIcon />
                                                        Copied!
                                                    </Fragment>
                                                )}
                                            </div>
                                        </CopyToClipboard>
                                    </div>
                                </div>

                                {/* Hidden Overlay for share btn dropdown */}
                                <div
                                    className={`hidden-overlay ${shareDropdown ? "show" : ""}`}
                                    onClick={() => setShareDropdown(!shareDropdown)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Delete Post Modal */}
                <ModalContainer show={deleteModal} onClose={toggleDeleteModal}>
                    <Modal>

                        {/* Modal Title */}
                        <div>
                            <h2>Delete Post?</h2>
                        </div>

                        {/* Modal Description */}
                        <p>This can’t be undone and it will be removed from your profile, the main feed, and from any search results.</p>

                        {/* Modal Actions */}
                        <Button 
                            onClick={handleDelete}
                            variant="outlined" 
                            className="sidebar__tweet danger"
                            fullWidth
                        >
                            Delete
                        </Button>
                        <Button 
                            onClick={toggleDeleteModal}
                            variant="outlined" 
                            className="sidebar__tweet ghost"
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Modal>
                </ModalContainer>

                {/* Create Message confirmation Modal */}  
                <ModalContainer show={messageModal} onClose={toggleMessageModal}>
                    <Modal>

                        {/* Modal Title */}
                        <div>
                            <h2>Start Conversation?</h2>
                        </div>

                        {/* Modal Description */}
                        <p>Start a private conversation with <b style={{color: '#333'}}>{post.username ? post.username : 'this person'}</b>.</p>
                        
                        {/* Modal Actions */}
                        <Button 
                            onClick={continueMessage}
                            variant="outlined" 
                            className="sidebar__tweet black"
                            fullWidth
                        >
                            Start Conversation
                        </Button>
                        <Button 
                            onClick={toggleMessageModal}
                            variant="outlined" 
                            className="sidebar__tweet ghost blackText"
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Modal>
                </ModalContainer>

                {/* Copied Link Modal */}
                <ModalContainer show={copiedModal} onClose={toggleCopiedModal} white>
                    <Modal border>

                        {/* Modal Title */}
                        <div>
                            <h2>Link Copied!</h2>
                        </div>

                        {/* Modal Description */}
                        <p>You can share this link with anyone.</p>
                        
                        {/* Modal Actions */}
                        <Button 
                            onClick={toggleCopiedModal}
                            variant="outlined" 
                            className="sidebar__tweet ghost"
                            fullWidth
                        >
                            Close
                        </Button>
                    </Modal>
                </ModalContainer>
            </Fragment>
        )
}

Detail.propTypes = {
    setAlert: PropTypes.func.isRequired, // Function prop to set an alert
    addLike: PropTypes.func.isRequired, // Function prop to add a like to a post
    deletePost: PropTypes.func.isRequired, // Function prop to delete a post
    getUserById: PropTypes.func.isRequired, // Function prop to retrieve user data by ID
    createChat: PropTypes.func.isRequired, // Function prop to create a chat conversation
    toggleAuthModal: PropTypes.func.isRequired, // Function prop to toggle the authentication modal
    auth: PropTypes.object.isRequired, // Object prop representing the authentication data for the user
}

const mapStateToProps = state => ({
    
    // Mapping the auth state from the Redux store to the 'auth' prop
    auth: state.auth
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setAlert, 
    addLike, 
    deletePost, 
    getUserById, 
    createChat, 
    toggleAuthModal 
})(Detail);
